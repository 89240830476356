import React, {Component} from "react";
import {Grid, Loader} from "semantic-ui-react";
import axios from "axios";
import InvestRequestedCard from "../widgets/invest.requested.card";

export default class AlwaysVCTab extends Component {
  state = {isLoaded: false, matchingRequest: []};

  async componentDidMount() {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/invest/always/matching/request/all`, {withCredentials: true});
    this.setState({
      matchingRequest: res.data,
      isLoaded: true,
    });
  }

  render() {
    return (
      <>
        {
          !this.state.isLoaded && <Loader active inline='centered'/>
        }
        {
          this.state.isLoaded && this.state.matchingRequest.length === 0 && <p className='color-gray pd-1'>요청한 투자 니즈가 없습니다.</p>
        }
        <Grid columns={3} doubling stackable>
          {
            this.state.matchingRequest.map((needs) =>
              <Grid.Column>
                <InvestRequestedCard requested={needs}/>
              </Grid.Column>
            )
          }
        </Grid>
      </>
    );
  }
}