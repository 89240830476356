export const noticeTypeOption = [
  {
    key: 'notice',
    text: '공고',
    value: 'notice'
  },
  {
    key: 'news',
    text: '뉴스',
    value: 'news'
  },
  {
    key: 'sharing',
    text: '정보공유',
    value: 'sharing'
  }
]

export const noticeTypeObj = {
  'notice': '공고',
  'news': '뉴스',
  'sharing': '정보공유'
}