import React, {Component} from "react";
import "@toast-ui/editor/dist/toastui-editor.css";
import {Editor} from "@toast-ui/react-editor";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Icon,
  Input,
} from "semantic-ui-react";
import axios from "axios";
import {noticeTypeOption} from "./notice.meta";
import {NoticeSurvey, NoticeSurveyView} from "./notice.survey";
import {S3UploadFile} from "../../../utills/s3-file-upload";

class NoticeUpdate extends Component {
  editorRef = React.createRef();

  uploadEditorImage = (blob) => {
    const formData = new FormData();
    formData.append("file", blob);

    console.log("in uploadEditorImage");
    console.log(blob);

    return axios.post(`${process.env.REACT_APP_API_URL}/files`, formData, {
      withCredentials: true,
    });
  };

  constructor(props) {
    super(props);
    this.query = new URLSearchParams(this.props.location.search);
    this.state = {
      isLoading: true,
      surveyTitle: "",
      surveyList: [],
      totalVote: 10,
      groupTypes: [],
      groupSubtypes: [],
      keywords: [],
      attachments: []
    };
  }

  async componentDidMount() {
    try {
      const uuid = this.query.get("uuid");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/board/notice/${uuid}`,
        {withCredentials: true}
      );
      const existNotice = response.data;

      const groupSubtypeOpts = existNotice.notice.notice_subtypes.map((type) => {
        return `[${type.parent_type}] ${type.name}`;
      });

      this.setState({
        isLoading: false,
        title: existNotice.notice.title,
        type: existNotice.notice.type,
        content: existNotice.notice.content,
        attachments: existNotice.notice.attachments ?? [],
        surveyTitle: existNotice.notice.survey_title,
        surveyList: existNotice.surveys,
        totalVote: existNotice.totalVote,
        groupTypes: existNotice.notice.notice_types,
        groupSubtypes: groupSubtypeOpts,
        keywords: existNotice.notice.notice_keywords,
      });
    } catch (e) {
      alert("게시글 정보를 불러오는데 실패했습니다.");
    }
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value,
    });
  };

  handleUpdate = async () => {
    const editorInstance = this.editorRef.current.getInstance();
    try {
      console.log(this.state);
      console.log(editorInstance.getMarkdown());
      await axios.put(
        `${process.env.REACT_APP_API_URL}/board/notice/${this.query.get(
          "uuid"
        )}`,
        {
          title: this.state.title,
          type: this.state.type,
          content: editorInstance.getMarkdown(),
        },
        {withCredentials: true}
      );
      alert("게시글을 수정했습니다!");
      window.location.href = "/board/notice";
    } catch (e) {
      alert("게시글 수정에 실패했습니다.");
    }
  };

  handleDelete = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/board/notice/${this.query.get(
          "uuid"
        )}`,
        {withCredentials: true}
      );
      alert("게시글을 삭제했습니다!");
      window.location.href = "/board/notice";
    } catch (e) {
      alert("게시글 삭제에 실패했습니다.");
    }
  };

  render() {
    return (
      <div>
        <h3>공지사항 수정</h3>
        {!this.state.isLoading ? (
          <Form>
            <Form.Input
              required
              label="공지글 제목"
              name="title"
              placeholder="제목을 입력해주세요."
              onChange={this.handleChange}
              value={this.state.title}
            />
            <Form.Select
              required
              label="공지글 타입"
              options={noticeTypeOption}
              name="type"
              placeholder="게시글 타입을 선택해주세요."
              onChange={this.handleChange}
              value={this.state.type}
            />
            <div className='ui field required'>
              <label>글 내용</label>
              <Editor
                height='500px'
                previewStyle="vertical"
                ref={this.editorRef}
                initialValue={this.state.content}
                hideModeSwitch={true}
                hooks={{
                  addImageBlobHook: async (blob, callback) => {
                    const {file, url, status, msg} = await S3UploadFile(blob, 'notice/content');
                    if (status === 0) {
                      alert(msg);
                      return false;
                    }
                    callback(encodeURI(url), file);
                    return false;
                  }
                }}
              />
            </div>

            <p className='fw-b'>첨부 파일</p>
            {
              this.state.attachments.map((file) =>
                <div className='mv-3'>
                  <a href={file} className='color-main'><Icon name='file'/> {window.decodeURI(file.split('/')[file.split('/').length - 1])}</a>
                </div>
              )
            }

            <NoticeSurveyView
              title={this.state.surveyTitle}
              checklist={this.state.surveyList}
              totalVote={this.state.totalVote}
              disable={true}
            />

            <div>
              <h3> 아래 대상들에게 알림을 보냈습니다. </h3>

              <p className="fw-b">그룹 타입</p>
              <div className="mb-5">
                {
                  this.state.groupTypes.length > 0
                    ? this.state.groupTypes.map((label) => {
                      return <span className="ui label mb-1">{label}</span>;
                    })
                    : <p className='color-gray'>대상을 설정하지 않았습니다.</p>
                }
              </div>
              <p className="fw-b">그룹 서브 타입</p>
              <div className="mb-5">
                {
                  this.state.groupSubtypes.length > 0
                    ? this.state.groupSubtypes.map((label) => {
                      return <span className="ui label mb-1">{label}</span>;
                    })
                    : <p className='color-gray'>대상을 설정하지 않았습니다.</p>
                }
              </div>
              <p className="fw-b">키워드</p>
              <div className="mb-5">
                {
                  this.state.keywords.length > 0
                    ? this.state.keywords.map((label) => {
                      return <span className="ui label mb-1">{label}</span>;
                    })
                    : <p className='color-gray'>대상을 설정하지 않았습니다.</p>
                }
              </div>
            </div>

            <Form.Group className="mv-7">
              <Form.Button onClick={this.handleUpdate}>수정</Form.Button>
              <Form.Button onClick={this.handleDelete} negative>
                삭제
              </Form.Button>
            </Form.Group>
          </Form>
        ) : null}
      </div>
    );
  }
}

export default NoticeUpdate;
