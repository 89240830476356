import React, {Component} from 'react'
import {Form} from 'semantic-ui-react'
import axios from 'axios'
import {DateTimeInput} from 'semantic-ui-calendar-react'

class SurveyUpdate extends Component {
  editorRef = React.createRef()

  constructor(props) {
    super(props)
    this.query = new URLSearchParams(this.props.location.search)
    this.state = {
      isLoading: true,
    }
  }

  async componentDidMount() {
    try {
      const uuid = this.query.get('uuid')
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/board/survey/${uuid}`)
      const existSurvey = response.data
      this.setState({
        isLoading: false,
        title: existSurvey.title,
        description: existSurvey.description,
        link_url: existSurvey.link_url,
        start_at: new Date(existSurvey.start_at),
        end_at: new Date(existSurvey.end_at),
      })
    } catch (e) {

    }
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value,
    })
  }

  handleDateChange = (e, data) => {
    if (e.type === "click") {
      this.setState({
        [data.name]: data.value,
      })
    }
  }

  handleUpdate = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/board/survey/${this.query.get(
          'uuid')}`, {
          'title': this.state.title,
          'description': this.state.description,
          'link_url': this.state.link_url,
          'start_at': this.state.start_at,
          'end_at': this.state.end_at,
        }, {withCredentials: true})
      alert('설문조사를 수정했습니다!')
      window.location.href = '/board/survey'
    } catch (e) {
      alert('설문조사 수정에 실패했습니다.')
    }
  }

  handleDelete = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/board/survey/${this.query.get(
          'uuid')}`, {withCredentials: true})
      alert('설문조사를 삭제했습니다!')
      window.location.href = '/board/survey'
    } catch (e) {
      alert('설문조사 삭제에 실패했습니다.')
    }
  }

  render() {
    return (
      <div>
        <h3>설문조사 수정</h3>
        {
          (!this.state.isLoading) ?
            <Form>
              <Form.Input required label='설문조사 제목' name='title'
                          placeholder="제목을 입력해주세요."
                          onChange={this.handleChange}
                          value={this.state.title}/>
              <Form.TextArea required label='설문조사 설명' name='description'
                             placeholder="설명을 입력해주세요."
                             onChange={this.handleChange}
                             value={this.state.description}/>
              <Form.Input required label='설문조사 링크' name='link_url'
                          placeholder="구글 설문지 링크를 입력해주세요."
                          onChange={this.handleChange}
                          value={this.state.link_url}/>
              <div className={'required field'}>
                <label>설문 기간</label>
                <Form.Group required>
                  <DateTimeInput name='start_at' placeholder="설문 시작"
                                 dateTimeFormat='YYYY-MM-DD HH:mm'
                                 dateFormat='YYYY-MM-DD HH:mm'
                                 onChange={this.handleDateChange}
                                 value={this.state.start_at}/>
                  <DateTimeInput name='end_at' placeholder="설문 종료"
                                 dateTimeFormat='YYYY-MM-DD HH:mm'
                                 dateFormat='YYYY-MM-DD HH:mm'
                                 onChange={this.handleDateChange}
                                 value={this.state.end_at}/>
                </Form.Group>
              </div>
              <br/>
              <Form.Group>
                <Form.Button onClick={this.handleUpdate}>수정</Form.Button>
                <Form.Button onClick={this.handleDelete}
                             negative>삭제</Form.Button>
              </Form.Group>
            </Form>
            : null
        }
      </div>
    )
  }
}

export default SurveyUpdate;