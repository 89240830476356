import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Activity from "./pages/activity";
import ActivityCreate from "./pages/activity.create";
import ActivityDetail from "./pages/activity.detail";

class ActivityModule extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path={"/program/activity/"} component={Activity} />
          <Route exact path={"/program/activity/create"} component={ActivityCreate} />
          <Route exact path={"/program/activity/detail/:id"} component={ActivityDetail} />
        </Switch>
      </div>
    );
  }
}

export default ActivityModule;
