import React, {Component} from 'react'
import {Table} from 'semantic-ui-react'
import moment from "moment";
import UserViewModal from "../user/user.view.modal";

class GroupUser extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1}>#</Table.HeaderCell>
              <Table.HeaderCell width={3}>이름</Table.HeaderCell>
              <Table.HeaderCell width={3}>타입</Table.HeaderCell>
              <Table.HeaderCell width={5}>이메일</Table.HeaderCell>
              <Table.HeaderCell width={3}>가입일</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.props.users ?
              this.props.users.map((user, idx) =>
                <Table.Row key={idx}>
                  <Table.Cell>{idx + 1}</Table.Cell>
                    <UserViewModal
                      userInfo={user}
                      trigger={<Table.Cell>
                        <a className='color-main pointer'>
                          {user.name}
                        </a>
                      </Table.Cell>}
                    />
                  <Table.Cell>
                    {user.user_type}
                  </Table.Cell>
                  <Table.Cell>
                    {user.email}
                  </Table.Cell>
                  <Table.Cell>
                    {moment(user.created_at).format('YYYY년 MM월 DD일 HH:mm')}
                  </Table.Cell>
                </Table.Row>
              )

              : null}

          </Table.Body>
        </Table>
      </div>
    )
  }
}

export default GroupUser
