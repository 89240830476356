import React, { Component } from 'react'
import {Route, Switch} from "react-router-dom";
import SurveyHome from './survey.home'
import SurveyCreate from './survey.create'
import { Divider } from 'semantic-ui-react'
import SurveyUpdate from './survey.update'

class SurveyModule extends Component {

  render () {
    return (
      <div>
        <h1>SSP 설문조사</h1>
        <p className='mb-7'>구글 설문지 또는 타 설문지 서비스 링크를 공유하고 관리할 수 있습니다.</p>
        <Switch>
          <Route exact path={"/board/survey"} component={SurveyHome}/>
          <Route exact path={"/board/survey/create"} component={SurveyCreate}/>
          <Route exact path={"/board/survey/update"} component={SurveyUpdate}/>
        </Switch>
      </div>
    )
  }
}

export default SurveyModule;
