import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import NoticeHome from './notice.home'
import NoticeCreate from './notice.create'
import NoticeUpdate from './notice.update'
import { Divider } from 'semantic-ui-react'

class NoticeModule extends Component {

  render () {
    return (
      <div>
        <h1>SPP 공지사항</h1>
        <p className='mb-7'>공고, 뉴스, 정보공유 게시글을 작성하고 관리할 수 있습니다.</p>
        <Switch>
          <Route exact path={'/board/notice'} component={NoticeHome}/>
          <Route exact path={'/board/notice/create'} component={NoticeCreate}/>
          <Route path={'/board/notice/update'} component={NoticeUpdate}/>
        </Switch>
      </div>
    )
  }
}

export default NoticeModule;
