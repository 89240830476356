import React, { Component } from 'react';
import axios from "axios";
import {Button, Table} from "semantic-ui-react";
import {Link} from "react-router-dom";

class HomeNewGroup extends Component {
  constructor (props) {
    super(props);
    this.state = {
      groups: []
    }
  }

  async componentDidMount() {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/statistics/latest/register/group?take=10`);
      this.setState({
        groups: res.data
      })
    } catch(e) {
      alert("그룹 정보 로딩에 실패했습니다.");
    }
  }

  render() {
    return (
      <div>
        <div style={{display: 'flex'}}>
          <h3 style={{flexGrow: 1}}>신규 그룹</h3>
          <Link to="/people/group">
            <Button style={{backgroundColor: "#006db4", color: "white"}} content='전체보기' icon='right arrow' labelPosition='right'/>
          </Link>
        </div>
        <Table celled selectable color='blue' compact>
          <Table.Header >
            <Table.Row>
              <Table.HeaderCell width={5}>그룹 이름</Table.HeaderCell>
              <Table.HeaderCell width={3}>타입</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              this.state.groups.map((group, idx) =>
                <Table.Row key={idx}>
                  <Table.Cell>
                    <Link to={`/people/group/${group.uuid}`}>
                      {group.name}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>
                    {group.type}
                  </Table.Cell>
                </Table.Row>
              )
            }
          </Table.Body>
        </Table>
      </div>
    );
  }

}

export default HomeNewGroup;