import React, { Component } from 'react'
import {Route, Switch} from "react-router-dom";
import PeopleHome from './people.home/people.home';
import UserModule from './user/user.module';
import GroupModule from './group/group.module';

class PeopleModule extends Component {

  render () {
    return (
      <div>
        <Switch>
          <Route exact path={"/people"} component={PeopleHome}/>
          <Route path={"/people/user"} component={UserModule}/>
          <Route path={"/people/group"} component={GroupModule}/>
        </Switch>
      </div>
    )
  }
}

export default PeopleModule;
