import { Component } from "react";
import { Button, Grid, Icon, Table} from "semantic-ui-react";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";

export default class Activity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
    };
  }
  async componentDidMount() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/activity`,
        { withCredentials: true }
      );
      console.log("activity Info", response.data);
      this.setState({
        activityList: response.data,
        isLoaded: true,
      });
    } catch (err) {
      alert("액티비티 정보를 로딩하는 데에 실패했습니다.");
    }
  }

  handleClick(id) {
    window.location.href = `/program/activity/detail/${id}`
  }

  render() {
    if (!this.state.isLoaded) return null;
    return (
      <div>
        <h1>SSP 액티비티</h1>
        <p>
          기존 SSP 액티비티를 관리하거나, 새로운 SSP 액티비티를 추가할 수
          있습니다.
        </p>
        {
              <Table celled selectable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={1}>#</Table.HeaderCell>
                      <Table.HeaderCell width={5}>액티비티 제목</Table.HeaderCell>
                      <Table.HeaderCell width={2}>상태</Table.HeaderCell>
                      <Table.HeaderCell width={4}>생성일</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
        
                  <Table.Body>
                    {
                      this.state.activityList.map((activity, idx) =>
                        <Table.Row key={idx} onClick={() => this.handleClick(activity.uuid)}>
                          <Table.Cell>{idx + 1}</Table.Cell>
                            <Table.Cell>
                              {activity.title}
                            </Table.Cell>
                          <Table.Cell>
                              <span className="ui label bg-lmain" style={{color:'white'}}>{activity.status}</span>
                          </Table.Cell>
                          <Table.Cell>
                            {moment(activity.created_at).format('YYYY년 MM월 DD일 HH:mm')}
                          </Table.Cell>
                        </Table.Row>
                       
                      )
                    }
                  </Table.Body>
                </Table>
        }

        <Link to={"/program/activity/create"}>
          <Button primary className="mt-5 mb-8">
            <Icon name="add" />
            새로운 액티비티 추가
          </Button>
        </Link>
        <Grid doubling columns={4}></Grid>
      </div>
    );
  }
}
