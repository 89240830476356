import React, { useState } from 'react'
import { Divider, Menu } from 'semantic-ui-react'
import DRU_Line from '../../components/statistics/DRU_Line'
import DAU_Line from '../../components/statistics/DAU_Line'
import DAG_Line from '../../components/statistics/DAG_Line'
import DUV_Line from '../../components/statistics/DUV_Line'
import { DateInput } from 'semantic-ui-calendar-react'
import moment from 'moment'
import DPV_Line from '../../components/statistics/DPV_Line'
import TPV_Table from '../../components/statistics/TPV_Table'

const Statistics = () => {
  const metric_list = ['DAU / DAG', 'DRU / DRG', 'DUV', 'DPV / TPV']

  const [startDate, setStartDate] = useState(
    moment().subtract(30, 'days').format('YYYY-MM-DD'),
  )
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))
  const [targetMetric, selectMetric] = useState(metric_list[0])

  const showMetricGraph = () => {
    switch (targetMetric) {
      case 'DAU / DAG':
        return <>
          <DAU_Line start_date={startDate} end_date={endDate}/>
          <br/>
          <DAG_Line start_date={startDate} end_date={endDate}/>
        </>
      case 'DRU / DRG':
        return <>
          <DRU_Line start_date={startDate} end_date={endDate}/>
        </>
      case 'DUV':
        return <>
          <DUV_Line start_date={startDate} end_date={endDate}/>
        </>
      case 'DPV / TPV':
        return <>
          <DPV_Line start_date={startDate} end_date={endDate}/>
          <br/>
          <TPV_Table start_date={startDate} end_date={endDate}/>
        </>
    }
  }

  return (
    <div>
      <h1>SSP 통계</h1>
      <p>SSP의 현황을 통계로 확인해보세요!</p>

      <Menu className='mt-8'>{
        metric_list.map(metric => {
          return (
            <Menu.Item onClick={() => selectMetric(metric)}>
              {metric}
            </Menu.Item>
          )
        })
      }</Menu>

      <div style={{ display: 'flex', gap: '1rem' }}>
        <DateInput
          dateFormat={'YYYY-MM-DD'}
          value={startDate}
          onChange={(e, target) => setStartDate(target.value)}/>
        <DateInput
          dateFormat={'YYYY-MM-DD'}
          value={endDate}
          onChange={(e, target) => setEndDate(target.value)}/>
      </div>

      <br/>

      {
        showMetricGraph()
      }
    </div>
  )
}

export default Statistics