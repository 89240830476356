import React, {Component} from "react";
import {Grid, Loader} from "semantic-ui-react";
import axios from "axios";
import RequestNeedsCard from "../widgets/request.needs.card";

export default class RequestNeedsTab extends Component {
  state = {isLoaded: false, needs: []}

  async componentDidMount() {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/bizday/${this.props.bizId}/matching/request/all`, {withCredentials: true});
      this.setState({
        isLoaded: true,
        needs: res.data,
      })
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return (
      <div>
        {
          this.state.isLoaded && this.state.needs.length === 0 && <p className='color-gray pd-1'>매칭 요청한 사업이 없습니다.</p>
        }
        {
          !this.state.isLoaded && <Loader active inline='centered'/>
        }
        <Grid columns={3} doubling stackable>
          {
            this.state.needs.map((m) =>
              <Grid.Column>
                <RequestNeedsCard venture_opinion={m.venture_opinion} venture_needs={m.venture_needs} partner_opinion={m.partner_opinion} partner_needs={m.partner_needs} is_matched={m.is_matched} />
              </Grid.Column>
            )
          }
        </Grid>
      </div>
    );
  }
}