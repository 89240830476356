import React, {Component} from 'react';
import {Container, Grid, Icon} from "semantic-ui-react";
import {Link} from "react-router-dom";

import './home.overview.scss';
import axios from "axios";

class HomeOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false
    }
  }

  async componentDidMount() {
    try {
      const resUser = await axios.get(`${process.env.REACT_APP_API_URL}/user/count`);
      const resGroup = await axios.get(`${process.env.REACT_APP_API_URL}/group/count`);
      const resItem = await axios.get(`${process.env.REACT_APP_API_URL}/market/item/count`);
      const resVideo = await axios.get(`${process.env.REACT_APP_API_URL}/video/count`);
      this.setState({
        numUser: resUser.data,
        numGroup: resGroup.data,
        numItem: resItem.data,
        numVideo: resVideo.data,
        isLoaded: true
      })
    } catch (err) {
      console.log(err);
      alert("Overview Fail...");
    }
  }

  render() {
    return (
      <div className='pv-6 mb-5'>
        {this.state.isLoaded ?
          <Grid doubling columns={4}>
            <Grid.Column>
              <div className="card-single">
                <div className="card-body">
                  <span><Icon name='user'/></span>
                  <div>
                    <h5>전체 유저수</h5>
                    <h4>{this.state.numUser}</h4>
                  </div>
                </div>
                <div className="card-footer">
                  <Link to="/people/user" style={{color: '#005386'}}>전체 보기</Link>
                  <span style={{color: '#005386', float: 'right'}}><Icon
                    name='arrow circle right'/></span>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="card-single">
                <div className="card-body">
                  <span><Icon name='group'/></span>
                  <div>
                    <h5>전체 그룹수</h5>
                    <h4>{this.state.numGroup}</h4>
                  </div>
                </div>
                <div className="card-footer">
                  <Link to="/people/group" style={{color: '#005386'}}>전체 보기</Link>
                  <span style={{color: '#005386', float: 'right'}}><Icon
                    name='arrow circle right'/></span>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="card-single">
                <div className="card-body">
                  <span><Icon name='gem'/></span>
                  <div>
                    <h5>전체 상품수</h5>
                    <h4>{this.state.numItem}</h4>
                  </div>
                </div>
                <div className="card-footer">
                  <Link to="/" style={{color: '#005386'}}>전체 보기</Link>
                  <span style={{color: '#005386', float: 'right'}}><Icon
                    name='arrow circle right'/></span>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="card-single">
                <div className="card-body">
                  <span><Icon name='video'/></span>
                  <div>
                    <h5>전체 영상수</h5>
                    <h4>{this.state.numVideo}</h4>
                  </div>
                </div>
                <div className="card-footer">
                  <Link to="/" style={{color: '#005386'}}>전체 보기</Link>
                  <span style={{color: '#005386', float: 'right'}}><Icon
                    name='arrow circle right'/></span>
                </div>
              </div>
            </Grid.Column>
          </Grid>
          : null}
      </div>
    );
  }
}

export default HomeOverview