import React, {Component} from "react";
import {Form} from "semantic-ui-react";
import axios from "axios";
import {S3UploadFile, S3UploadSvg} from "../../../../utills/s3-file-upload";

export default class CreateBizdayGroup extends Component {
  state = {groups: [], allowed_groups: []};

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  async componentDidMount() {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/bizday/biz-group`, {withCredentials: true});
    this.setState({
      groups: res.data.map((group) => {
        return {
          key: group.uuid,
          text: group.name,
          value: group.uuid,
          description: group.type,
          image: {avatar: true, src: group.profile_url ?? 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'}
        };
      }),
    });
  }

  createBizGroup = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/bizday/group`, {
        name: this.state.name,
        slug: this.state.slug,
        description: this.state.description,
        allowed_groups: this.state.allowed_groups,
        thumbnail_url: this.state.thumbnail_url
      }, {withCredentials: true});
      window.location.replace("/program/bizday");
    } catch (e) {
      console.log(e);
      alert("새로운 비즈데이 그룹 생성에 실패했습니다.")
    }
  }

  handleImageUpload = async (e) => {
    const {status, url, msg} = await S3UploadSvg(e.target.files[0], "bizday/group/thumbnail");
    if (status === 1) {
      this.setState({
        [e.target.name]: url,
      })
    } else {
      alert(msg);
    }
  }

  render() {
    const renderLabel = (label) => ({
      content: `${label.text}`,
    })

    return (
      <div>
        <h1>새로운 비즈데이 그룹</h1>
        <p className='mb-8'>새로운 비즈데이를 운영 그룹을 추가합니다.</p>
        <Form>
          <Form.Input required label='비즈데이 그룹 이름' name='name' placeholder="그룹 이름을 입력해주세요."
                      onChange={this.handleChange} value={this.state.name}/>
          <Form.Input required label='비즈데이 그룹 Slug' name='slug'
                      placeholder="URL에 사용할 Slug를 입력해주세요. (poscossp.com/bizday/[Slug])" onChange={this.handleChange}
                      value={this.state.slug}/>
          <Form.TextArea rows={5} required label='비즈데이 소개' name='description' placeholder="해당 그룹의 비즈데이 소개를 입력해주세요."
                         onChange={this.handleChange} value={this.state.description}/>

          <div className='ui required field'>
            <label>비즈데이 섬네일</label>
          </div>

          <div>
            <img
              style={{
                width: '200px',
                height: '200px',
                borderRadius: '10px',
                border: '1px solid #eaeaea',
                objectFit: 'contain',
              }}
              src={this.state.thumbnail_url ?? "https://react.semantic-ui.com/images/wireframe/square-image.png"}
            />
          </div>
          <label htmlFor="upload">
            <span className='ui button basic mt-4 mb-6'>섬네일 업로드 (SVG)</span>
            <input name='thumbnail_url' accept=".svg" type="file" id="upload" style={{display: 'none'}}
                   onChange={this.handleImageUpload}/>
          </label>

          <Form.Dropdown
            required
            label='권한 그룹'
            placeholder='니즈 업로드 권한이 있는 그룹을 모두 선택해주세요.'
            fluid
            search
            selection
            multiple
            options={this.state.groups}
            renderLabel={renderLabel}
            value={this.state.allowed_groups}
            name='allowed_groups' onChange={this.handleChange}
          />
          <Form.Button className='mv-7' primary onClick={this.createBizGroup}>생성</Form.Button>
        </Form>

      </div>
    )
  }
}