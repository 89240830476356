import React, {Component} from "react";
import axios from "axios";
import {Button, Container, Divider, Form, Icon, Loader, Modal, Table} from "semantic-ui-react";
import * as XLSX from "xlsx";
import {Link} from "react-router-dom";
import moment from "moment";
import FileUploadButton from "../../../utills/file_upload_button";

export default class GroupAutoCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            xlsxFile: {},
            isLoading: false,
            groupList: [],
        }

    }

    makeGroup = async (groupInfo) => {
        const emailVal = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,5}$/;
        if (!emailVal.test(groupInfo.Admin_email)) return 'failed';


        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/admin/group/manage`, {
                'name': groupInfo.Name,
                'type': groupInfo.Type,
                'subtype': groupInfo.Subtype,
                'admin_email': groupInfo.Admin_email,
                'admin_name': groupInfo.Admin_name,
            }, {withCredentials: true});
        } catch (err) {
            return 'failed'
        }
    }

    onChange = (e) => {
        this.setState({
            fileName: e.target.files[0].name,
            xlsxFile: e.target
        })
    }
    readExcel = () => {
        try {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve((() => {
                    const data = reader.result;
                    const workBook = XLSX.read(data, {type: 'binary'})
                    return workBook.SheetNames.map(sheetName => {
                        const row = XLSX.utils.sheet_to_json(workBook.Sheets[sheetName]);
                        return JSON.parse(JSON.stringify(row));
                    })[0]
                })());
                reader.onerror = reject
                reader.readAsBinaryString(this.state.xlsxFile.files[0])
            }).catch(err => {
                console.log(err);
                alert('파일을 확인해주세요!');
                return null;
            });
        } catch (err) {
            alert('파일을 확인해주세요!');
            return null;
        }
    }

    validateForm(list) {
        if('Name' in list[0] && 'Type' in list[0] && 'Admin_email' in list[0]
            && 'Admin_name' in list[0])
            return true
        return false;
    }

    handleOnce = async () => {
        let groupList = await this.readExcel();
        if (groupList == null) return;

        console.log('list before', groupList)
        groupList = groupList.filter((item) => item.Name)

        if(!this.validateForm(groupList)) {
            alert("파일 형식을 확인해주세요!")
            return;
        }
        console.log('list after', groupList)

        this.setState({
            groupList: groupList,
            isLoading: true,
        }, async () => {


            const res = [];
            for (const item of this.state.groupList) {
                if (item.Subtype)
                    item.Subtype = item.Subtype.replace(' ', '').split(',')
                item.result = await this.makeGroup(item) !== 'failed';
                res.push(item);
            }

            this.setState({
                isLoading: false,
                groupList: res
            })
        })
    }


    render() {
        let loading = this.state.isLoading;
        return (
            <>
                <h3 className='mt-8'>자동으로 그룹 추가</h3>
                <p className='mb-7'>기본 정보가 기록된 엑셀(.xlsx 확장자)파일을 선택하고, 아래의 '한번에 보내기' 버튼을 누르세요.</p>

                <div>
                    {loading ? <Loader inline={'centered'} active>그룹 생성 중...</Loader> :
                        <div>
                            <div className='is-flex mb-7 is-vcentered' style={{flexWrap: 'wrap'}}>
                                <a className='ui button mr-5 mb-5' href={'/docs/example.xlsx'}><Icon name='cloud download'/> 예시 파일 다운로드</a>

                                <FileUploadButton
                                    className='mb-5'
                                    name='file'
                                    icon='upload'
                                    label={'엑셀 파일 선택'}
                                    accept='.xlsx'
                                    type="file"
                                    handleChange={this.onChange}
                                />
                                <p className='ml-5 mb-5 fw-b'>{this.state.fileName}</p>
                                {/*<input className={'ph-4 mb-7'} accept='.xlsx' type="file" onChange={this.onChange}/>*/}
                            </div>

                            <Button primary onClick={this.handleOnce}>한번에 그룹 생성하기</Button>

                            {this.state.groupList.length > 0 &&
                            <div className={'pv-7'}>
                                <h2 className='mv-7'>아래 그룹들을 생성했습니다.</h2>
                                {<Table celled selectable>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell width={1}>#</Table.HeaderCell>
                                            <Table.HeaderCell width={1}>결과</Table.HeaderCell>
                                            <Table.HeaderCell width={3}>그룹 이름</Table.HeaderCell>
                                            <Table.HeaderCell width={2}>타입</Table.HeaderCell>
                                            <Table.HeaderCell width={3}>서브타입</Table.HeaderCell>
                                            <Table.HeaderCell width={2}>대표자 이름</Table.HeaderCell>
                                            <Table.HeaderCell width={4}>대표자 이메일</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {this.state.groupList.map((group, idx) =>
                                            <Table.Row key={idx}>
                                                <Table.Cell>{idx + 1}</Table.Cell>
                                                <Table.Cell>
                                                    {group.result ? <div
                                                            className={'color-green fw-b'}> 성공 </div> :
                                                        <div className={'color-red fw-b'}> 실패 </div>}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {group.Name}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {group.Type}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {group.Subtype && group.Subtype.map((subtype) => {
                                                        return <span className="ui label ">{subtype}</span>
                                                    })}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {group.Admin_name}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {group.Admin_email}
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                        }
                                    </Table.Body>

                                </Table>
                                }
                            </div>
                            }
                        </div>

                    }
                </div>
            </>
        );
    }
}
