import React, { Component } from "react";
import { Modal, Button, Table } from "semantic-ui-react";
import axios from "axios";

export default class VoterListModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      users: [],
    };
  }
  
  async handleGetData(optUuid) {
    this.setState({open: true});
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/board/notice/survey/voters/${optUuid}`,
        { withCredentials: true }
      );
      this.setState({
        users: response.data,
      });
    } catch (e) {
      alert("투표한 유저 로딩에 실패했습니다.");
    }
    console.log("muyahooo", this.state.users);
  }

  render() {
    return (
      <Modal
        onClose={() => {
          this.setState({ open: false });
        }}
        onOpen={() => {
          this.setState({ open: true });
        }}
        open={this.state.open}
      >
        <Modal.Header>투표한 유저</Modal.Header>
        <Modal.Content scrolling>
          <Table celled selectable color="blue" compact>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={3}>이름</Table.HeaderCell>
                <Table.HeaderCell width={3}>소속</Table.HeaderCell>
                <Table.HeaderCell width={5}>이메일</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.state.users.map((user, idx) => (
                <Table.Row key={idx}>
                  <Table.Cell>
                    {user.name}
                  </Table.Cell>
                  <Table.Cell>{user.group ? user.group.name : '-'}</Table.Cell>
                  <Table.Cell>
                    {user.email}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Modal.Content>

        <Modal.Actions>
          <Button
            onClick={() => {
              this.setState({ open: false });
            }}
          >
            확인
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
