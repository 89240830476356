import axios from "axios";
import React, { Component } from "react";
import { Editor } from "@toast-ui/react-editor";
import { Button, Form, Icon } from "semantic-ui-react";
import { S3UploadFile } from "../../../../utills/s3-file-upload";

export default class ActivityUpdate extends Component {
  editorRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
    };
  }
  async componentDidMount() {
    try {
      const id = this.props.id;

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/activity/${id}`
      );
      console.log('response', response)
      const res = response.data;
      const statusList = await axios.get(
        `${process.env.REACT_APP_API_URL}/activity/status`
      );
      console.log('res', res)
      console.log('statusList', statusList)

      this.setState({
        uuid: res.uuid,
        title: res.title,
        desc: res.description,
        poster_url: res.poster_url,
        status: res.status,
        statusList: statusList.data,
        isLoaded: true,
      });
    } catch (e) {
      console.log(e);
    }
  }
  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value,
    });
  };

  async postData() {
    const editorInstance = this.editorRef.current.getInstance();

    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/activity/${this.state.uuid}`,
        {
          title: this.state.title,
          description: editorInstance.getMarkdown(),
          poster_url: this.state.poster_url,
          status: this.state.status,
        },
        { withCredentials: true }
      );
      alert("액티비티를 저장했습니다!");
      window.location.href = "/program/activity";
    } catch (e) {
      alert("액티비티 저장에 실패했습니다.");
    }
  }
  handleDelete = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/activity/${this.state.uuid}`,{ withCredentials: true})
      
      alert("액티비티를 삭제했습니다!");
      window.location.href = "/program/activity";
    }catch (e) {
      alert("액티비티 삭제에 실패했습니다.");
    }
  }
  handleSave = async () => {
    if (this.state.poster_url_file) {
      const { file, url } = await S3UploadFile(
        this.state.poster_url_file,
        "poster_url"
      );
      this.setState(
        {
          poster_url: url,
        },
        () => {
          this.postData();
        }
      );
    } else this.postData();
  };
  handleFileUpload = async (event) => {
    if (event.target.files.length) {
      const fileReader = new FileReader();
      let file = event.target.files[0];
      fileReader.onloadend = () => {
        this.setState({
          [event.target.name + "_file"]: file,
          [event.target.name]: fileReader.result,
        });
      };
      fileReader.readAsDataURL(file);
    }
  };

  render() {
    if (!this.state.isLoaded) return null;

    const statusList = this.state.statusList;
    const statusButtons = Object.entries(statusList).map((type) => {
      const [key, value] = type;
      if (this.state.status == value) return <Button primary> {value} </Button>;
      return (
        <Button
          onClick={() => {
            this.setState({ status: value });
          }}
        >
          {" "}
          {value}{" "}
        </Button>
      );
    });

    return (
      <>
          <Form>
            <div className='mb-6'>
              <div className="ui field required fw-b">
                <label>액티비티 상태</label>
              </div>
              {statusButtons}
            </div>
            <Form.Input
              required
              label="액티비티 제목"
              name="title"
              placeholder="제목을 입력해주세요."
              onChange={this.handleChange}
              value={this.state.title}
            />
            <div className="ui field required fw-b">
              <label>액티비티 포스터</label>
            </div>
            <img
              src={
                this.state.poster_url ??
                "https://react.semantic-ui.com/images/wireframe/square-image.png"
              }
              style={{
                width: "210px",
                height: "300px",
                objectFit: "cover",
              }}
            />
            <div className="mt-5 mb-7">
              <Form.Input
                accept="image/*"
                type="file"
                name="poster_url"
                onChange={this.handleFileUpload}
              />
            </div>
            <p className="fw-b">액티비티 설명</p>
            <Editor
              previewStyle="vertical"
              ref={this.editorRef}
              initialValue={this.state.desc}
              hooks={{
                addImageBlobHook: async (blob, callback) => {
                  const response = await this.uploadEditorImage(blob);
                  const { file, url } = response.data;
                  callback(url, file); // callback(src, alt);
                  return false;
                },
              }}
            />

            <br />

            <Button className="mv-7 mr-4" primary onClick={this.handleSave}>
              저장
            </Button>
            <Button className="mv-7" onClick={this.handleDelete}>
              삭제
            </Button>
          </Form>
      </>
    );
  }
}
