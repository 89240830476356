import React, {Component} from "react";
import {Button, Form, Grid, Image, Modal} from "semantic-ui-react";
import axios from "axios";
import PartnerCard from "../widgets/partner.card";
import {S3UploadFile} from "../../../../utills/s3-file-upload";

class PartnerSection extends Component {
  state = { open: false, partner: [] }

  open = () => this.setState({ open: true })
  close = () => this.setState({ open: false })

  async componentDidMount () {
    this.getPartner();
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  handleCreatePartner = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/program/partner`, {
        'program_type': this.props.programType,
        'name': this.state.newName,
        'description': this.state.newDesc,
        'profile_img_url': this.state.newProfile,
        'website_url': this.state.newWebsite,
        'gitple_id': this.state.newGitpleId,
        'group_uuid': this.state.newGroupUuid
      }, {withCredentials: true});

      this.setState({
        newName: '',
        newDesc: '',
        newProfile: null,
        newWebsite: '',
        newGitpleId: '',
        newGroupUuid: ''
      })
      alert("파트너를 추가했습니다.");
      this.close();

      this.getPartner();
    } catch (e) {
      alert("파트너 추가에 실패했습니다.");
    }
  }

  async getPartner() {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/program/partner?type=${this.props.programType}`);
  
    this.setState({
      partner: response.data
    })
  }

  handleImageUpload = async (e) => {
    const {status, url, msg} = await S3UploadFile(e.target.files[0], "partner/profile");
    if (status === 1) {
      this.setState({
        [e.target.name]: url,
      })
    } else {
      alert(msg);
    }
  }

  render() {
    return(
      <div>
        <Button primary className='mt-5 mb-8' onClick={this.open}>새로운 파트너 추가</Button>

        <Grid doubling stackable columns={4} verticalAlign='middle'>
          {
            this.state.partner.map((p, idx) =>
              <Grid.Column key={idx}>
                <PartnerCard partner={p} programType={this.props.programType} updated={this.getPartner.bind(this)}/>
              </Grid.Column>
            )
          }
        </Grid>

        {/* 새로운 파트너 추가 */}
        <Modal
          onClose={() => this.close()}
          onOpen={() => this.open()}
          open={this.state.open}
        >
          <Modal.Header>새로운 파트너 추가하기</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form>
                <div className='has-item-centered' style={{flexDirection: 'column'}}>
                  <img style={{
                    border: '1px solid #eaeaea',
                    objectFit: 'cover',
                    width: '150px',
                    height: '150px',
                    borderRadius: '75px'
                  }} src={this.state.newProfile ?? 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'} alt='partner profile'/>
                  <label htmlFor="upload">
                    <span className='ui button basic mv-5'>프로필 사진 업로드</span>
                    <input name='newProfile' type="file" id="upload" style={{display: 'none'}} onChange={this.handleImageUpload}/>
                  </label>
                </div>

                <Form.Input
                  required label='파트너 이름' name='newName'
                  placeholder="파트너 이름을 입력해주세요."
                  onChange={this.handleChange} value={this.state.newName}
                />
                <Form.Input
                  required label='파트너 설명' name='newDesc'
                  placeholder="간단한 설명을 입력해주세요."
                  onChange={this.handleChange} value={this.state.newDesc}
                />
                <Form.Input
                  label='홈페이지 URL' name='newWebsite'
                  placeholder="홈페이지가 있다면 주소를 입력해주세요."
                  onChange={this.handleChange} value={this.state.newWebsite}
                />
                {/*<Form.Input*/}
                {/*  label='깃플챗 ID' name='newGitpleId'*/}
                {/*  placeholder="깃플챗 계정이 있다면 ID를 입력해주세요."*/}
                {/*  onChange={this.handleChange} value={this.state.newGitpleId}*/}
                {/*/>*/}
                <Form.Input
                  label='채팅 그룹 ID' name='newGroupUuid'
                  placeholder="채팅이 가능한 그룹이 있다면 해당 그룹 ID를 입력해주세요. ( '사용자 관리' > '그룹 관리'에서 확인할 수 있습니다. )"
                  onChange={this.handleChange} value={this.state.newGroupUuid}
                />
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.close()}>
              취소
            </Button>
            <Button
              content="생성"
              labelPosition='right'
              icon='checkmark'
              onClick={this.handleCreatePartner}
              color='blue'
            />
          </Modal.Actions>
        </Modal>

      </div>
    )
  }
}

export default PartnerSection