import React, {Component} from "react";
import {Button, Divider, Form} from "semantic-ui-react";
import axios from "axios";

// Default 1차 카테고리
const category1 = [
  {key: '이차전지소재', text: '이차전지소재', value: '이차전지소재'},
  {key: '수소', text: '수소', value: '수소'},
  {key: '스마트팩토리 안전', text: '스마트팩토리 안전', value: '스마트팩토리 안전'},
  {key: '스마트팩토리 환경', text: '스마트팩토리 환경', value: '스마트팩토리 환경'},
  {key: '스마트팩토리 물류', text: '스마트팩토리 물류', value: '스마트팩토리 물류'},
  {key: '식량', text: '식량', value: '식량'},
  {key: '스마트건설', text: '스마트건설', value: '스마트건설'},
  {key: 'LNG', text: 'LNG', value: 'LNG'},
  {key: '친환경발전', text: '친환경발전', value: '친환경발전'},
  {key: '기타', text: '기타', value: '기타'},
];

// Default 2차 카테고리
const category2 = [
  {key: '바이오', text: '바이오', value: '바이오'},
  {key: '소재/부품/장비', text: '소재/부품/장비', value: '소재/부품/장비'},
  {key: 'IT', text: 'IT', value: 'IT'},
  {key: '기타', text: '기타', value: '기타'},
];

class UpdateBizday extends Component {
  state = {category_1: [], category_2: [], status: false, catOption1: category1, catOption2: category2}

  async componentDidMount() {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/bizday/${this.props.match.params.bizId}`, {withCredentials: true});
    const bizday = res.data;
    this.setState({
      'title': bizday.title,
      'notice': bizday.notice,
      'notes': bizday.notes,
      'category_1': bizday.category_1,
      'category_2': bizday.category_2,
      'status': bizday.status
    });
  }

  toggle = () => this.setState((prevState) => ({status: !prevState.status}));
  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  updateBizday = async () => {
    try {
      await axios.patch(`${process.env.REACT_APP_API_URL}/bizday/${this.props.match.params.bizId}`, {
        'title': this.state.title,
        'notice': this.state.notice,
        'notes': this.state.notes,
        'category_1': this.state.category_1,
        'category_2': this.state.category_2,
        'status': this.state.status
      }, {withCredentials: true});
      window.location.replace(`/program/bizday/${this.props.match.params.slug}/${this.props.match.params.bizId}`);
    } catch (e) {
      console.log(e);
      alert("Biz Day 내용 변경에 실패했습니다.");
    }
  }

  render() {
    const renderLabel = (label) => ({
      color: 'blue',
      content: `${label.text}`,
    })

    return (
      <div>
        <h1>비즈데이 수정</h1>
        <p className='mb-8'>비즈데이 내용을 수정합니다.</p>
        <Form>
          <Form.Input required label='Biz Day 제목' name='title' placeholder="제목을 입력해주세요. (ex. 2021년 2분기, 제 17회 비즈데이 등)"
                      onChange={this.handleChange} value={this.state.title}/>
          <Form.Input label='공지' name='notice' placeholder="공지를 입력해주세요. (Ex: 2021년 2분기 Biz Day를 시작합니다!)"
                      onChange={this.handleChange} value={this.state.notice}/>
          <Form.TextArea rows={10} label='참고사항' name='notes' placeholder="참고 사항을 적어주세요. (Ex: 주제, 접수기간, 연락처 등)"
                         onChange={this.handleChange} value={this.state.notes}/>

          <div className='ui field'>
            <label>모집 카테고리</label>
            <Form.Dropdown
              placeholder='1차 카테고리를 등록해주세요.'
              fluid
              search
              selection
              multiple
              allowAdditions
              onAddItem={(event, data) => this.state.catOption1.push({key: data.value, text: data.value, value: data.value})}
              options={this.state.catOption1.concat(this.state.category_1.map((cat)=>{ return { key: cat, text: cat, value: cat } }))}
              renderLabel={renderLabel}
              name='category_1' onChange={this.handleChange}
              value={this.state.category_1}
            />
            <Form.Dropdown
              placeholder='2차 카테고리를 등록해주세요.'
              fluid
              search
              selection
              multiple
              allowAdditions
              onAddItem={(event, data) => this.state.catOption2.push({key: data.value, text: data.value, value: data.value})}
              options={this.state.catOption2.concat(this.state.category_2.map((cat)=>{ return { key: cat, text: cat, value: cat } }))}
              renderLabel={renderLabel}
              name='category_2' onChange={this.handleChange}
              value={this.state.category_2}
            />
          </div>

          <div className='ui field is-flex'>
            <label>신청 접수 받기</label>
            <Form.Checkbox className='ml-5' toggle name='status' onChange={this.toggle} checked={this.state.status}/>
          </div>

          <Button className='mv-7' onClick={this.updateBizday}>수정</Button>
        </Form>
      </div>
    )
  }
}

export default UpdateBizday;