import React, {Component} from 'react';
import {Button, Table} from 'semantic-ui-react'
import axios from 'axios';
import moment from "moment";
import {Link} from 'react-router-dom'

class SurveyHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveys: []
    }
  }

  async componentDidMount() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/board/survey`);
      this.setState({
        surveys: response.data
      })
    } catch (e) {

    }
  }

  render() {
    return (
      <div>
        <div>
          <Link to={'/board/survey/create'}>
            <Button primary className='mb-7'>설문 생성</Button>
          </Link>
        </div>
        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1}>#</Table.HeaderCell>
              <Table.HeaderCell width={8}>제목</Table.HeaderCell>
              <Table.HeaderCell width={4}>설문 기간</Table.HeaderCell>
              <Table.HeaderCell width={3}>작성일</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {
              this.state.surveys.map((survey, idx) =>
                <Table.Row key={idx}>
                  <Table.Cell>{idx + 1}</Table.Cell>
                  <Table.Cell>
                    <Link to={`/board/survey/update?uuid=${survey.uuid}`}>
                      {survey.title}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>
                    {moment(survey.start_at).format('YYYY-MM-DD HH:mm')} &nbsp; ~ &nbsp;
                    {moment(survey.end_at).format('YYYY-MM-DD HH:mm')}
                  </Table.Cell>
                  <Table.Cell>
                    {moment(survey.created_at).format('YYYY년 MM월 DD일 HH:mm')}
                  </Table.Cell>
                </Table.Row>
              )
            }
          </Table.Body>
        </Table>
      </div>
    )
  }
}

export default SurveyHome;
