import React, {Component} from "react";
import {Button, Icon, Label, Modal} from "semantic-ui-react";
import {Viewer} from "@toast-ui/react-editor";
import RoundProfile from "../../../../utills/profile";

export default class NeedsCard extends Component {
  state = {open: false}
  open = () => this.setState({open: true})
  close = () => this.setState({open: false})

  render() {
    return (
      <Modal
        onClose={() => this.close()}
        onOpen={() => this.open()}
        open={this.state.open}
        trigger={
          <div className='p-card pv-5 ph-6 hover-zoom pointer'>
            <p className='mb-0 fw-b fs-1'>{this.props.needs.title}</p>
            <div className='mt-5'>
              {
                this.props.needs.needs_type === 'VENTURE' &&
                <div>
                  <Label className='mb-1'>{this.props.needs.venture_type}</Label>
                  <Label className='mb-1'>{this.props.needs.invest_phase}</Label>
                </div>
              }
              <Label className='mb-1'>{this.props.needs.category_1}</Label>
              <Label className='mb-1'>{this.props.needs.category_2}</Label>
            </div>

            <div className='is-flex is-vcentered' style={{float: 'right'}}>
              <p className='color-black mb-0 mr-5 fw-b'>{this.props.needs.group.name}</p>
              <RoundProfile src={this.props.needs.group.profile_url} size={35}/>
            </div>
          </div>
        }
      >
        <Modal.Header>{this.props.needs.title}
          <div className='mt-3'>
            {
              this.props.needs.needs_type === 'VENTURE' &&
              <>
                <Label className='mb-1'>{this.props.needs.venture_type}</Label>
                <Label className='mb-1'>{this.props.needs.invest_phase}</Label>
              </>
            }
            <Label className='mb-1'>{this.props.needs.category_1}</Label>
            <Label className='mb-1'>{this.props.needs.category_2}</Label>
          </div>
        </Modal.Header>
        <Modal.Content>
          <Viewer
            referenceDefinition={true}
            initialValue={this.props.needs.description}
          />
          <h3>첨부 파일</h3>
          {
            this.props.needs.attachments && this.props.needs.attachments.map((file) => <div className='mt-5'>
              <a href={file} className='color-main'><Icon name='file'/> {
                window.decodeURIComponent(file.split('/')[file.split('/').length - 1])
              }</a>
            </div>)
          }
        </Modal.Content>
        <Modal.Actions>
          <div className='is-flex is-vcentered' style={{float: 'left'}}>
            <RoundProfile src={this.props.needs.group.profile_url} size={35}/>
            <p className='color-black mb-0 ml-5 fw-b'>{this.props.needs.group.name}</p>
          </div>
          <Button onClick={this.close}>확인</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}