import { useEffect, useState } from 'react'
import axios from 'axios'
import { Table } from 'semantic-ui-react'

const TPV_Table = (props) => {
  const [data, setData] = useState([])
  const { start_date, end_date } = props

  useEffect(async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/statistics/TPV?startDate=${start_date}&endDate=${end_date}`)
      setData(res.data)
    } catch (err) {
      alert('통계 데이터를 불러오는데 실패했습니다.')
    }
  }, [start_date, end_date])

  return (
    <div>
      <h2 className='mt-7'>프로그램 조회 순위</h2>
      <Table celled stackable>
        <Table.Header>
          <Table.HeaderCell>
            #
          </Table.HeaderCell>
          <Table.HeaderCell>
            프로그램 이름
          </Table.HeaderCell>
          <Table.HeaderCell>
            프로그램 타입
          </Table.HeaderCell>
          <Table.HeaderCell>
            조회수
          </Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {
            data.map((row, idx) => {
              return (
                <Table.Row>
                  <Table.Cell>
                    {idx + 1}
                  </Table.Cell>
                  <Table.Cell>
                    <a target="_blank"
                       href={`https://poscossp.com/program/detail/${row.program_uuid}`}
                    >
                      {row.title}
                    </a>
                  </Table.Cell>
                  <Table.Cell>
                    {row.type}
                  </Table.Cell>
                  <Table.Cell>
                    {row.TPV}
                  </Table.Cell>
                </Table.Row>
              )
            })
          }
        </Table.Body>
      </Table>
    </div>
  )
}

export default TPV_Table