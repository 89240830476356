import React, {Component} from "react";
import '@toast-ui/editor/dist/toastui-editor.css';
import {Editor} from '@toast-ui/react-editor';
import {Form} from "semantic-ui-react";
import axios from "axios";
import { S3UploadFile } from "../../../../utills/s3-file-upload";

class ActivityCreate extends Component {
editorRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  

  handleFileUpload = async (event) => {
    if (event.target.files.length) {
      const fileReader = new FileReader();
      let file = event.target.files[0];
      fileReader.onloadend = () => {
        this.setState({
          [event.target.name + "_file"] : file,
          [event.target.name] : fileReader.result
        });
      }
      fileReader.readAsDataURL(file);
    }
    
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  handleSave = async () => {

    if (this.state.poster_url_file) {
      const {file, url} = await S3UploadFile(this.state.poster_url_file, "poster_url");
      this.setState({
        poster_url: url
      }, () => {this.postData()})
    }
    else {
      this.postData()
    }
  }

  async postData() {    
    const editorInstance = this.editorRef.current.getInstance()

    try {
        await axios.post(`${process.env.REACT_APP_API_URL}/activity`, {
          'title': this.state.title,
          'description': editorInstance.getMarkdown(),
          'poster_url': this.state.poster_url,
        }, {withCredentials: true});
        alert('액티비티를 생성했습니다!');
        window.location.href = "/program/activity"
      } catch (e) {
        alert("액티비티 저장에 실패했습니다.");
      }
  }
  



  render() {
    return (
      <div>
        <h3>새로운 액티비티 생성</h3>
        <Form>
          <Form.Input required label='액티비티 제목' name='title' placeholder="제목을 입력해주세요." onChange={this.handleChange}/>
          <div className='ui field required fw-b'>
                <label>액티비티 포스터</label>
            </div>
                <img
                  src={this.state.poster_url ?? "https://react.semantic-ui.com/images/wireframe/square-image.png"}
                  style={{
                    width: '210px',
                    height: '300px',
                    objectFit: 'cover',
                  }}/>
                <div className='mt-5 mb-7'>
                  <Form.Input
                    accept='image/*'
                    type='file' name='poster_url' onChange={this.handleFileUpload}/>
                </div>
            <p className='fw-b'>액티비티 설명</p>
          <Editor
            previewStyle="vertical"
            ref={this.editorRef}
            hooks={{
              addImageBlobHook: async (blob, callback) => {
                const response = await this.uploadEditorImage(blob);
                const {file, url} = response.data;
                callback(url, file); // callback(src, alt);
                return false;
              }
            }}
          />
          
          <br/>

          <Form.Button className='mv-7' primary onClick={this.handleSave}>저장</Form.Button>
        </Form>
      </div>
    )
  }
}

export default ActivityCreate;