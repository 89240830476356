import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Line } from 'react-chartjs-2'

const DUV_Line = (props) => {
  const [DUV_data, setDUV_data] = useState([])
  const {start_date, end_date} = props;

  useEffect(async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/statistics/DUV?startDate=${start_date}&endDate=${end_date}`);
      setDUV_data(res.data);
    } catch (err) {
      alert("통계 데이터를 불러오는데 실패했습니다.")
    }
  }, [start_date, end_date])


  const data = {
    labels: Object.keys(DUV_data),
    datasets: [
      {
        label: 'daily uploaded video',
        data:  Object.values(DUV_data),
        fill: false,
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: 'rgba(255, 99, 132, 0.2)',
      },
    ],
  }

  return (
    <div>
      <h2 className='mt-7'>일일 업로드 영상</h2>
      <Line data={data}/>
    </div>
  )

}

export default DUV_Line