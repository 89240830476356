import React, { Component } from 'react'
import {Route, Switch} from "react-router-dom";
import NoticeModule from './notice/notice.module'
import SurveyModule from './survey/survey.module'
import CitizenModule from "./corpoCitizen/citizen.module";

class BoardModule extends Component {

  render () {
    return (
      <div>
        <Switch>
          <Route path={"/board/notice"} component={NoticeModule}/>
          <Route path={"/board/survey"} component={SurveyModule}/>
          <Route path={"/board/citizen"} component={CitizenModule}/>
        </Switch>
      </div>
    )
  }
}

export default BoardModule;
