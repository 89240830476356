import React, {Component} from "react";
import {
  Button,
  Card, CardContent, Divider, Form, Grid,
  Icon, Image, Label, Progress, Statistic,
  TextArea,
} from "semantic-ui-react";
import axios from "axios";
import moment from "moment";
import DetailMsgAdmin from "../../bizday/widgets/detail.msg.admin";
import StepperTitle, {StepperContent} from "../../bizday/widgets/Stepper";
import RoundProfile from "../../../../utills/profile";
import InvestMatchedDetailCard from "./widgets/invest.matched.detail.card";

class InvestMatchedDetail extends Component {

  state = {progress: [], adminMsg: ''}

  async getProgress() {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/invest/progress/${this.props.match.params.matchId}`, {withCredentials: true});
      this.setState({
        progress: res.data,
        adminMsg: ''
      })
    } catch (e) {
      console.log(e);
    }
  }

  async componentDidMount() {
    this.getProgress();
  }

  handleAdminMsg = (e, data) => {
    this.setState({
      adminMsg: data.value
    })
  }

  handleProgress = async (msg, statusCode, cType, extra) => {
    if (msg.trim() === '') {
      alert("내용이 없습니다.");
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/invest/progress`, {
        'matched_id': this.props.match.params.matchId,
        'msg': msg,
        'status_code': statusCode,
        'creator_type': cType,
        'extra': extra ?? ''
      }, {withCredentials: true});
      await this.getProgress();
    } catch (e) {
      alert("진행 상태 변경에 실패했습니다.");
    }
  }

  render() {
    return (
      <div>
        <h1>매칭된 니즈</h1>

        <div className='pt-5'>

          {
            this.state.progress.map((p, idx) =>
              <div key={idx}>
                <StepperTitle title={p.title} step={p.step}/>
                <StepperContent>
                  {
                    p.progress.map((m, idx) => {
                      if (m.message_type === 'MATCHED_NEEDS') {
                        let opinion = JSON.parse(m.matched_needs.vc_opinion);

                        return (
                          <div>
                            <div className='p-card pd-1 mb-5'>
                              <p>{JSON.parse(m.message).msg}</p>
                              <p style={{color: 'gray', fontSize: '0.9em'}}>{moment(m.updated_at).format('YYYY년 MM월 DD일 HH:mm')}</p>
                            </div>
                            <InvestMatchedDetailCard matched={m.matched_needs} opinion={opinion}/>
                          </div>
                        );
                      }
                      else { // Default
                        if (m.creator_type === 'BOT') {
                          return (
                            <div className='p-card pd-1 mb-5'>
                              <p>{JSON.parse(m.message).msg}</p>
                              <p style={{color: 'gray', fontSize: '0.9em'}}>{moment(m.updated_at).format('YYYY년 MM월 DD일 HH:mm')}</p>
                            </div>
                          )
                        } else if (m.creator_type === 'ADMIN') {
                          return (
                            <DetailMsgAdmin content={JSON.parse(m.message).msg} date={moment(m.updated_at).format('YYYY년 MM월 DD일 HH:mm')}/>
                          )
                        } else {
                          return (
                            <div style={{display: 'flex'}} className='mv-5'>
                              <RoundProfile src={m.profile.profile_url} size={40}/>
                              <div className='p-card pd-1 ml-5' style={{marginTop: 0, borderRadius: '20px'}}>
                                <p>{JSON.parse(m.message).msg}</p>
                                <p style={{color: 'gray', fontSize: '0.9em'}}>{moment(m.updated_at).format('YYYY년 MM월 DD일 HH:mm')}</p>
                              </div>
                            </div>
                          )
                        }
                      }
                    })
                  }
                  {
                    this.state.progress.length === idx + 1 && <div className='mv-5'>
                      <CardContent>
                        <Form className='mb-5'>
                          <TextArea fluid placeholder='전달할 내용을 입력해주세요.' onChange={this.handleAdminMsg} value={this.state.adminMsg}/>
                        </Form>
                      </CardContent>
                      <Button fluid content='올리기' primary onClick={() => this.handleProgress(this.state.adminMsg, idx + 1, 'ADMIN')}/>
                    </div>
                  }
                  {
                    this.state.progress.length === idx + 1 && this.state.progress.length === 1 && <div>
                      <Button onClick={() => this.handleProgress('🚩 펀드레이징을 시작합니다.', 2, 'BOT')} className='mt-5' basic content="'Initiate 펀드레이징' 단계로 넘어가기" labelPosition='right' icon='arrow right'/>
                    </div>
                  }
                  {
                    this.state.progress.length === idx + 1 && this.state.progress.length === 2 && <div>
                      <Button onClick={() => this.handleProgress('1️⃣ 펀드레이징 1 Round를 시작합니다.', 3, 'BOT')} className='mt-5' basic content="'펀드레이징 1 Round' 단계로 넘어가기" labelPosition='right' icon='arrow right'/>
                    </div>
                  }
                  {
                    this.state.progress.length === idx + 1 && this.state.progress.length === 3 && <div>
                      <Button onClick={() => this.handleProgress('2️⃣ 펀드레이징 2 Round를 시작합니다.', 4, 'BOT')} className='mt-5' basic content="'펀드레이징 2 Round' 단계로 넘어가기" labelPosition='right' icon='arrow right'/>
                    </div>
                  }
                  {
                    this.state.progress.length === idx + 1 && this.state.progress.length === 4 && <div>
                      <Button onClick={() => this.handleProgress('3️⃣ 펀드레이징 3 Round를 시작합니다.', 5, 'BOT')} className='mt-5' basic content="'펀드레이징 3 Round' 단계로 넘어가기" labelPosition='right' icon='arrow right'/>
                    </div>
                  }
                  {
                    this.state.progress.length === idx + 1 && this.state.progress.length === 5 && <div>
                      <Button onClick={() => this.handleProgress('4️⃣ 펀드레이징 4 Round를 시작합니다.', 6, 'BOT')} className='mt-5' basic content="'펀드레이징 4 Round' 단계로 넘어가기" labelPosition='right' icon='arrow right'/>
                    </div>
                  }
                  {
                    this.state.progress.length === idx + 1 && this.state.progress.length === 6 && <div>
                      <Button onClick={() => this.handleProgress('5️⃣ 펀드레이징 5 Round를 시작합니다.', 7, 'BOT')} className='mt-5' basic content="'펀드레이징 5 Round' 단계로 넘어가기" labelPosition='right' icon='arrow right'/>
                    </div>
                  }
                  {
                    this.state.progress.length === idx + 1 && this.state.progress.length === 7 && <div>
                      <Button onClick={() => this.handleProgress('6️⃣ 펀드레이징 6 Round를 시작합니다.', 8, 'BOT')} className='mt-5' basic content="'펀드레이징 6 Round' 단계로 넘어가기" labelPosition='right' icon='arrow right'/>
                    </div>
                  }
                  {
                    this.state.progress.length === idx + 1 && this.state.progress.length === 8 && <div>
                      <Button onClick={() => this.handleProgress('7️⃣ 펀드레이징 7 Round를 시작합니다.', 9, 'BOT')} className='mt-5' basic content="'펀드레이징 7 Round' 단계로 넘어가기" labelPosition='right' icon='arrow right'/>
                    </div>
                  }
                  {
                    this.state.progress.length === idx + 1 && this.state.progress.length === 9 && <div>
                      <Button onClick={() => this.handleProgress('8️⃣ 펀드레이징 8 Round를 시작합니다.', 10, 'BOT')} className='mt-5' basic content="'펀드레이징 8 Round' 단계로 넘어가기" labelPosition='right' icon='arrow right'/>
                    </div>
                  }
                  {
                    this.state.progress.length === idx + 1 && this.state.progress.length === 10 && <div>
                      <Button onClick={() => this.handleProgress('📈 사후 관리를 시작합니다.', 11, 'BOT')} className='mt-5' basic content="'사후 관리' 단계로 넘어가기" labelPosition='right' icon='arrow right'/>
                    </div>
                  }
                  {
                    this.state.progress.length === idx + 1 && this.state.progress.length === 11 && <div>
                      <Button onClick={() => this.handleProgress('🥳 투자 유치 축하드립니다!', 11, 'BOT')} className='mt-5' basic content="🥳 투자 유치 축하드립니다!"/>
                    </div>
                  }
                </StepperContent>
              </div>
            )
          }
        </div>

      </div>
    )
  }
}

export default InvestMatchedDetail;