import React, {Component} from "react";
import {Grid, Loader} from "semantic-ui-react";
import axios from "axios";
import InvestMatchedCard from "../widgets/invest.matched.card";

export default class AlwaysMatchedTab extends Component {
  state = {isLoaded: false, needs: []};

  async componentDidMount() {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/invest/matched/needs/always`, {withCredentials: true});
    this.setState({
      needs: res.data,
      isLoaded: true,
    });
  }

  render() {
    return (
      <>
        {
          !this.state.isLoaded && <Loader active inline='centered'/>
        }
        {
          this.state.isLoaded && this.state.needs.length === 0 && <p className='color-gray pd-1'>매칭된 투자 니즈가 없습니다.</p>
        }
        <Grid columns={3} doubling stackable>
          {
            this.state.needs.map((needs) =>
              <Grid.Column>
                <InvestMatchedCard matched={needs}/>
              </Grid.Column>
            )
          }
        </Grid>
      </>
    );
  }
}