import React, {Component} from "react";
import {Divider} from "semantic-ui-react";
import {Route, Switch} from "react-router-dom";
import Group from "./group";
import GroupUuid from "./group.uuid";
import GroupCreate from "./group.create";
import GroupAutoCreate from "./group.auto_create";

class GroupModule extends Component {

  render() {
    return (
      <div>
        <h1>그룹 관리</h1>
        <Switch>
          <Route exact path={"/people/group"} component={Group}/>
          <Route exact path={"/people/group/create"} component={GroupCreate}/>
          <Route exact path={"/people/group/auto_create"} component={GroupAutoCreate}/>
          <Route exact path={"/people/group/:uuid"} component={GroupUuid}/>
        </Switch>
      </div>
    )
  }
}

export default GroupModule;