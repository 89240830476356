import React, {Component} from "react";
import '@toast-ui/editor/dist/toastui-editor.css';
import {Button, Card, Divider, Icon, Input} from "semantic-ui-react";
import axios from "axios";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};


class CitizenCategory extends Component {

  constructor(props) {
    super(props);
    this.state = {
      typeList: []
    };
    this.onDragEnd = this.onDragEnd.bind(this);
    this.getTypeList()
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  async getTypeList() {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/board/corpoCitizen/type/all`, {withCredentials: true});
      this.setState({
        typeList: res.data
      })
    } catch (e) {
      alert("문제가 발생했습니다. 잠시 뒤 다시 시도하거나 개발자에게 문의하세요.");
    }
  }

  createNewType = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/board/corpoCitizen/type`, {
        'title': this.state.title,
      }, {withCredentials: true});
      this.setState({title: ''});
      this.getTypeList()
    } catch (e) {
      alert("문제가 발생했습니다. 잠시 뒤 다시 시도하거나 개발자에게 문의하세요.");
    }
  }

  updateOrder = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/board/corpoCitizen/type/order`, {
        'list': this.state.typeList,
      }, {withCredentials: true});
      alert("순서가 성공적으로 변경되었습니다.");
    } catch (e) {
      alert("문제가 발생했습니다. 잠시 뒤 다시 시도하거나 개발자에게 문의하세요.");
    }
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const typeList = reorder(
      this.state.typeList,
      result.source.index,
      result.destination.index
    );

    this.setState({
      typeList
    });
  }


  render() {
    return (
      <div>
        <h3>카테고리 생성</h3>
        <p className='mb-7'>새로 생성할 기업시민 활동 탭의 타이틀을 입력한 뒤 <b>'새로운 카테고리 생성'</b> 버튼을 눌러주세요.</p>
        <Input style={{width: '100%'}} name='title' placeholder="타이틀을 입력해주세요." value={this.state.title}
               onChange={this.handleChange}/>
        <Button color='blue' className='mv-7' onClick={this.createNewType}>새로운 카테고리 생성</Button>

        <h3>카테고리 순서 변경</h3>
        <p className='mb-7'>카드 오른쪽에 있는 <Icon name='content' className='color-main'/>버튼을 클릭 후 드래그하여 탭 순서를 변경 후
          아래 <b>'저장'</b> 버튼을 눌러주세요.</p>

        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {this.state.typeList.map((item, index) => (
                  <Draggable key={item.uuid} draggableId={item.uuid} index={index}>
                    {(provided, snapshot) => (
                      <div
                        className='mb-5'
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className='p-card ph-3 pv-1'>
                          <div style={{padding: '0.5rem', display: 'flex', alignItems: 'center'}}>
                            <p style={{width: '100%', margin: '.5rem'}}>{item.title}</p>
                            <Icon name='content' className='color-main mh-5'/>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <Button className='mv-7' onClick={this.updateOrder}>저장</Button>
      </div>
    )
  }
}

export default CitizenCategory;