import React, {Component} from "react";
import {Grid, Icon} from 'semantic-ui-react'
import User from './user.svg';
import Group from './group.svg';
import {Link} from "react-router-dom";

class PeopleHome extends Component {

  render() {
    return (
      <div>
        <h1>사용자 관리</h1>
        <p>SSP 회원 및 그룹을 관리합니다.</p>

        <Grid className='mt-7' columns={4} doubling>
          <Grid.Column>
            <Link to='/people/user'>
              <div className='p-card hover-zoom'>
                <div className='pd-1'>
                  <p style={{fontWeight: 'bold', fontSize: '1.3em', color: '#000000DE'}}>회원 관리 <span
                    style={{color: '#005386', float: 'right'}}><Icon
                    name='arrow circle right'/></span></p>
                </div>
                <div className='p-box-1by1'>
                  <div className='p-box-ch is-flex is-vcentered'>
                    <div className='pd-1'>
                      <img style={{width: '100%', maxHeight: '300px'}} src={User}/>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </Grid.Column>
          <Grid.Column>
            <Link to='/people/group'>
              <div className='p-card hover-zoom'>
                <div className='pd-1'>
                  <p style={{fontWeight: 'bold', fontSize: '1.3em', color: '#000000DE'}}>그룹 관리 <span
                    style={{color: '#005386', float: 'right'}}><Icon
                    name='arrow circle right'/></span></p>
                </div>
                <div className='p-box-1by1'>
                  <div className='p-box-ch is-flex is-vcentered'>
                    <div className='pd-1'>
                      <img style={{width: '100%', maxHeight: '300px'}} src={Group}/>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </Grid.Column>
        </Grid>
      </div>
    )
  }
}

export default PeopleHome;