import React, {Component} from "react";
import {Button, Divider, Icon, Label, Loader, Tab} from "semantic-ui-react";
import axios from "axios";
import {Link} from "react-router-dom";
import "../bizday.css";
import NeedsTab from "../tabs/needs.tab";
import MatchedNeedsTab from "../tabs/matched.needs.tab";
import RequestNeedsTab from "../tabs/request.needs.tab";

class Bizday extends Component {
  state = {isLoaded: false, groupName: '', bizId: '', ventureNeeds: [], partnerNeeds: []};

  async componentDidMount() {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/bizday/${this.props.match.params.bizId}`, {withCredentials: true});
      this.setState({
        isLoaded: true,
        bizday: res.data,
        bizId: res.data.uuid,
        groupName: res.data.biz_group.name
      })

      const ventureNeeds = await axios.get(`${process.env.REACT_APP_API_URL}/bizday/${this.props.match.params.bizId}/needs/type/VENTURE`, {withCredentials: true});
      const partnerNeeds = await axios.get(`${process.env.REACT_APP_API_URL}/bizday/${this.props.match.params.bizId}/needs/type/PARTNER`, {withCredentials: true});
      this.setState({
        ventureNeeds: ventureNeeds.data,
        partnerNeeds: partnerNeeds.data
      })
      console.log(res.data);
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const panes = [
      {
        menuItem: '매칭된 사업',
        render: () => <MatchedNeedsTab groupName={this.state.groupName} bizId={this.state.bizId} ventureNeeds={this.state.ventureNeeds} partnerNeeds={this.state.partnerNeeds}/>,
      },
      {
        menuItem: '벤처 니즈',
        render: () => <NeedsTab needs={this.state.ventureNeeds}/>,
      },
      {
        menuItem: `${this.state.groupName} 니즈`,
        render: () =>  <NeedsTab needs={this.state.partnerNeeds}/>,
      },
      {
        menuItem: '매칭 요청',
        render: () => <RequestNeedsTab bizId={this.state.bizId}/>,
      },
    ];

    return (
      this.state.isLoaded ?
        <div>
          <h1>{this.state.bizday.title}</h1>
          <p>진행중인 내용을 수정하거나, 벤처 요청 니즈를 확인 후 매칭할 수 있습니다.</p>
          <Link to={`/program/bizday/${this.props.match.params.slug}/${this.props.match.params.bizId}/update-bizday`}>
            <Button primary className='mt-5 mb-7 mr-5'><Icon name='edit'/> 내용 수정하기</Button>
          </Link>
          <div className='p-card pv-5 ph-6 mb-6'>
            <div className='is-flex'>
              <p className='fw-b' style={{flexGrow: 1}}><Icon name='bullhorn'/> {this.state.bizday.notice ?? ''}</p>
              <div style={{flexShrink: 0, textAlign: 'center'}}>
                <Icon className={`fs-3 ${this.state.bizday.status ? 'color-main' : 'color-gray'}`} name={this.state.bizday.status ? 'toggle on' : 'toggle off'}/>
                <p className={`fw-b ${this.state.bizday.status ? 'color-main' : 'color-gray'}`}>{this.state.bizday.status ? '모집 중' : '모집 마감'}</p>
              </div>
            </div>
            <p className='fw-b'><Icon name='pin'/> 참고 사항</p>
            <p style={{wordBreak: 'break-word', whiteSpace: 'pre-wrap'}}>{this.state.bizday.notes}</p>

            <p className='fw-b'><Icon name='tag'/>모집 카테고리</p>
            <div>
              {
                this.state.bizday.category_1.map((cat) => <Label className='mb-1'>{cat}</Label>)
              }
            </div>
            <div>
              {
                this.state.bizday.category_2.map((cat) => <Label className='mb-1'>{cat}</Label>)
              }
            </div>
          </div>

          <Tab id='biz-tap' menu={{secondary: true}} panes={panes}/>

        </div>
        : <Loader active inline='centered' />
    )
  }
}

export default Bizday;