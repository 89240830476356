import axios from "axios";
import { Component } from "react";
import { Divider, Dropdown, Form } from "semantic-ui-react";

let typeOpts = [];
let subtypeOpts = [];
let keywordOpts = [];

let types = [];
let subtypes = [];
let keywords = [];

export default class NotiTypeSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      types: [],
      subtypes: [],
      keywords: [],
    };
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value,
    }, () => this.setParentState(e));
  };

  setParentState = (event) => {
    this.props.setNotiTarget(this.state.types, this.state.subtypes, this.state.keywords);
  };

  async componentDidMount() {
    try {

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/group/meta`);
      const meta = response.data;
      console.log('meta', meta);
      const groupTypeOpts = Object.entries(meta.group_type).map((type) => {
        const [key, value] = type;
        return {
          key: key,
          text: value,
          value: value
        }
      });

      const groupSubtypeOpts = meta.group_subtype.map((type) => {
        return {
          key: type.id,
          text: `[${type.parent_type}] ${type.name}`,
          value: type.id
        }
      });
      

      const keywords = await axios.get(`${process.env.REACT_APP_API_URL}/keyword`);

      const keywordOpts = keywords.data.map((keyword) => {
        return {
          key: keyword.name,
          text: keyword.name,
          value: keyword.name
        }
      });

      this.setState({
        groupTypeOpts: groupTypeOpts,
        groupSubtypeOpts: groupSubtypeOpts,
        keywordOpts: keywordOpts
      })
    } catch (e) {
      alert(
        "오류가 발생했습니다. 잠시 뒤 다시 시도하거나 문제가 지속되면 문의해주세요."
      );
    }

  }

  render() {
    return (
      <div className='mt-7'>
        <h3> 알림을 보낼 대상을 설정해주세요.</h3>
        <Form.Dropdown
          label="그룹 타입"
          placeholder="그룹 타입을 설정해주세요."
          fluid
          search
          selection
          multiple
          options={this.state.groupTypeOpts}
          value={this.state.groupTypes}
          name="types"
          onChange={this.handleChange}
        />
        <Form.Dropdown
          label="그룹 서브 타입"
          placeholder="그룹 서브 타입을 설정해주세요."
          fluid
          search
          selection
          multiple
          options={this.state.groupSubtypeOpts}
          value={this.state.groupSubtypes}
          name="subtypes"
          onChange={this.handleChange}
        />
        <Form.Dropdown
          label="키워드"
          placeholder="키워드를 설정해주세요."
          fluid
          search
          selection
          multiple
          options={this.state.keywordOpts}
          value={this.state.keywords}
          name="keywords"
          onChange={this.handleChange}
        />
      </div>
    );
  }
}
