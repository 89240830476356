import React, {Component} from "react";
import { Divider } from 'semantic-ui-react'
import {Route, Switch} from "react-router-dom";
import UserHome from "./user.home";

class UserModule extends Component {

  render() {
    return (
      <div>
        <h1>회원 관리</h1>
        <Switch>
          <Route exact path={"/people/user"} component={UserHome}/>
        </Switch>
      </div>
    )
  }
}

export default UserModule;