import React, {Component} from 'react';
import {Menu, Container, Image, Dropdown, Icon} from 'semantic-ui-react'
import {withRouter} from "react-router-dom";
import MediaQuery from "react-responsive/src";
import axios from "axios";
import UserContext from "../../UserContext";
import RoundProfile from "../../utills/profile";

class Navbar extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false
    }
  }

  goto = (loc) => {
    this.props.history.push(loc);
  }

  login = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/login?redirect=${window.location.href}`;
  }

  logout = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/auth/logout`, {withCredentials: true});
  }

  render() {
    const user = this.context;
    console.log(user);

    return (
      <div style={{
        borderBottom: '1px solid rgba(0,0,0,.1)',
        background: '#005386',
        position: 'fixed',
        width: '100%',
        top: 0,
        zIndex: 10
      }} className='pv-1'>
        <Container>
          <Menu secondary inverted>
            <MediaQuery maxWidth={768}>
              <Menu.Item onClick={this.props.openSidebar}>
                <Icon name='sidebar' style={{margin: 0}}/>
              </Menu.Item>
            </MediaQuery>

            <Menu.Item className='nav-logo' style={{paddingLeft: 0, paddingRight: 0}}
                       onClick={this.goto.bind(this, '/')}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <Image size='tiny' src={'/img/ssp-logo-white.svg'}/>
                <p className='ml-5 fw-b'>관리자</p>
              </div>
            </Menu.Item>

            <MediaQuery minWidth={768}>
              <Menu.Item
                name='사용자 관리'
                position='right'
                onClick={this.goto.bind(this, '/people')}
              />
              <Dropdown item text='프로그램'>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={this.goto.bind(this, '/program/intro')}>SSP 소개</Dropdown.Item>
                  <Dropdown.Item onClick={this.goto.bind(this, '/program/bizday')}>Biz Day</Dropdown.Item>
                  <Dropdown.Item onClick={this.goto.bind(this, '/investment')}>투자 지원</Dropdown.Item>
                  <Dropdown.Item onClick={this.goto.bind(this, '/program/activity')}>SSP 액티비티</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown item text='게시판'>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={this.goto.bind(this, '/board/notice')}>공지</Dropdown.Item>
                  <Dropdown.Item onClick={this.goto.bind(this, '/board/survey')}>설문지</Dropdown.Item>
                  <Dropdown.Item onClick={this.goto.bind(this, '/board/citizen')}>기업시민 활동</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Menu.Item name='통계' onClick={this.goto.bind(this, '/statistics')}/>
            </MediaQuery>
            {
              user ?
                <Menu.Item position='right'>
                  <Dropdown
                    trigger={
                      <span>
                        <RoundProfile src={user.profile_url} size={35}/>
                    </span>
                    }
                    pointing='top right'
                    icon={null}
                  >
                    <Dropdown.Menu>
                      <Dropdown.Item text='SSP 홈으로' icon='home'
                                     onClick={() => window.location.href = 'https://poscossp.com'}/>
                      <Dropdown.Item text='로그아웃' icon='log out' onClick={this.logout} href={"/"}/>
                    </Dropdown.Menu>
                  </Dropdown>
                </Menu.Item>
                :
                <Menu.Item position='right' onClick={this.login}><Icon name='user'/>로그인</Menu.Item>
            }
          </Menu>
        </Container>
      </div>
    );
  }
}

export default withRouter(Navbar)