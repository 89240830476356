import React, {Component} from "react";
import {Button, Icon} from "semantic-ui-react";
import {Link} from "react-router-dom";
import axios from "axios";
import moment from "moment";

class InvestFestivalListPage extends Component {
  state = {isLoaded: false, openInvList: [], closeInvList: []};

  async componentDidMount() {
    const openRes = await axios.get(`${process.env.REACT_APP_API_URL}/invest/festival?status=open`, {withCredentials: true});
    const closeRes = await axios.get(`${process.env.REACT_APP_API_URL}/invest/festival?status=close`, {withCredentials: true});

    this.setState({
      openInvList: openRes.data,
      closeInvList: closeRes.data,
      isLoaded: true,
    });
  }

  render() {
    return (
      <div className='p-container'>
        <h1>투자 지원</h1>
        <p>투자자와 벤처를 매칭시키고 진행 상황을 관리합니다.</p>
        <p><b>투자 행사</b>를 개최하여 벤처들의 니즈를 신청받거나, <b>상시 모집</b>에서 벤처들의 기존 니즈를 모두 확인할 수 있습니다.</p>

        <Link to={'/investment/festival/always'}>
          <div className='p-card pv-5 ph-7 hover-zoom pointer mv-6'>
            <p className='fw-b fs-1 color-black'>상시 모집</p>
            <p className='color-main mb-3'><Icon name='group'/> 모든 투자자</p>
            <p className='color-main'><Icon name='time'/> 상시 접수 중</p>
          </div>
        </Link>

        <h3 className='mt-7'>진행 중인 투자 행사</h3>

        <Link to={'/investment/new-festival'}>
          <Button primary className=''><Icon name='add'/> 새로운 투자 행사 개최</Button>
        </Link>

        {
          this.state.isLoaded && this.state.openInvList.length === 0 && <p className='color-gray pd-1'>진행 중인 투자 행사가 없습니다.</p>
        }
        {
          this.state.openInvList.map((e) =>
            <Link to={`/investment/festival/${e.uuid}`}>
              <div className='p-card pv-5 ph-7 hover-zoom pointer mv-6'>
                <p className='fw-b fs-1 color-black'>{e.title}</p>
                <p className='color-main mb-3'><Icon name='group'/> 참여 VC - {e.host_groups.map((h, idx) => <span>{h.name}{idx + 1 < e.host_groups.length ? ', ' : ''}</span>)}</p>
                <p className='color-main'><Icon name='time'/> 신청기간 - {moment(e.start_at).format('MM월 DD일 HH:mm')} ~ {moment(e.end_at).format('MM월 DD일 HH:mm')}</p>
              </div>
            </Link>
          )
        }


        <h3 className='mt-7'>종료된 투자 행사</h3>
        {
          this.state.isLoaded && this.state.openInvList.length === 0 && <p className='color-gray pd-1'>종료된 투자 행사가 없습니다.</p>
        }
        {
          this.state.closeInvList.map((e) =>
            <Link to={`/investment/festival/${e.uuid}`}>
              <div className='p-card pv-5 ph-7 hover-zoom pointer mv-6'>
                <p className='fw-b fs-1 color-black'>{e.title}</p>
                <p className='color-gray mb-3'><Icon name='group'/> 참여 VC - {e.host_groups.map((h, idx) => <span>{h.name}{idx + 1 < e.host_groups.length ? ', ' : ''}</span>)}</p>
                <p className='color-gray'><Icon name='time'/> 신청기간 - {moment(e.start_at).format('YYYY년 MM월 DD일')} ~ {moment(e.end_at).format('YYYY년 MM월 DD일')}</p>
              </div>
            </Link>
          )
        }
      </div>
    );
  }
}

export default InvestFestivalListPage;