import React, {Component, createRef} from "react";
import {Button, Checkbox, Form, Icon, Input, Divider} from "semantic-ui-react";
import VoterListModal from "./voter.list.modal";

class NoticeSurvey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveyTitle: "",
      surveyChecklist: [],
    };
  }

  setParentState = (event) => {
    if (this.props.disable) return;

    this.props.setSurveyInfo(
      this.state.surveyTitle,
      this.state.surveyChecklist
    );
  };

  componentDidMount() {
    this.setState({
      surveyTitle: this.props.title ?? "",
      surveyChecklist: this.props.checklist ?? [],
    });
  }

  addOption = (e, data) => {
    this.state.surveyChecklist = this.state.surveyChecklist.concat("");
    this.setState();
    this.setParentState(e);
  };

  removeOption = (e, data) => {
    this.state.surveyChecklist = this.state.surveyChecklist.filter(
      (check, ind) => ind !== data.id
    );
    this.setState();
    this.setParentState(e);
  };

  handleOption = (e, data) => {
    this.state.surveyChecklist = this.state.surveyChecklist.map(
      (check, idx) => {
        return idx === data.id ? data.value : check;
      }
    );
    this.setState();
    this.setParentState(e);
  };

  handleChange = (e, data) => {
    this.state.surveyTitle = data.value;
    this.setState();
    this.setParentState(e);
  };

  render() {
    return (
      <div className="mt-6">
        <div className="pv-4">
          <Form.Input
            label="설문지 질문"
            name="surveyTitle"
            placeholder="질문을 입력해주세요."
            onChange={this.handleChange}
            disabled={this.props.disable}
            value={this.state.surveyTitle}
          />

          {this.state.surveyChecklist.map((cl, ind) => (
            <div className="mb-5 is-flex is-vcentered">
              <Checkbox disabled/>
              <Input
                className="ml-4 half-w"
                id={ind}
                placeholder="선택지를 입력해주세요."
                onChange={this.handleOption}
                disabled={this.props.disable}
                value={this.state.surveyChecklist[ind]}
              />

              <Button id={ind} className="ml-4" onClick={this.removeOption}>
                {" "}
                <Icon name="minus"/>
                삭제
              </Button>
            </div>
          ))}
        </div>
        <Button onClick={this.addOption}>
          <Icon name="add"/>
          옵션 추가
        </Button>
      </div>
    );
  }
}


export class NoticeSurveyView extends Component {
  constructor(props) {
    super(props);
    this.child = createRef();
    this.state = {
      votePercentage: []
    }

  }

  componentDidMount() {
    this.setState({
      votePercentage: this.props.checklist.map((val, idx) => (parseInt(val.count) / (parseInt(this.props.totalVote) === 0 ? 1 : parseInt(this.props.totalVote)) * 100)),
    })
  }

  handleModalData = (optionUuid) => {
    this.child.current.handleGetData(optionUuid);
  }

  render() {
    return (
      <div>
        <VoterListModal ref={this.child}/>

        <div className='mv-7'>
          <p className='fs-1'> {this.props.title} </p>
          {
            this.props.checklist.map((val, idx) => (
              <div className="mb-2 is-flex is-vcentered">
                <div className={`p-round-little border-a pd-1 mb-3 mr-4 half-w`}
                     style={{position: 'relative', overflow: 'hidden'}}>
                  <div className={'bg-lgray full-h'} style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    zIndex: '-1',
                    width: `${this.state.votePercentage[idx]}%`
                  }}/>
                  {val.option}
                </div>
                <div className='has-text-centered' style={{width: '100px'}}>
                  {`${parseFloat(this.state.votePercentage[idx]).toFixed(1)}% (${val.count}명)`}
                </div>
                <Button className="ml-4" onClick={() => this.handleModalData(val.uuid)}>
                  투표한 사람 보기
                </Button>
              </div>

            ))}
        </div>
      </div>
    );
  }
}

export default NoticeSurvey;


