import React, {Component} from 'react';
import {Button, Table} from 'semantic-ui-react'
import axios from 'axios';
import moment from "moment";
import {Link} from 'react-router-dom'
import UserViewModal from "../../../components/user/user.view.modal";

class UserHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: []
    }
  }

  async componentDidMount() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/user`);
      console.log(response.data);
      this.setState({
        users: response.data
      })
    } catch (e) {
      alert("유저 정보 로딩에 실패했습니다.");
    }
  }

  handleDelete = async () => {
    try {
      const uuid = this.query.get('uuid')
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/user/${uuid}`)
      alert('유저를 삭제했습니다!')
      window.location.href = '/people/user'
    } catch (e) {
      alert('유저 삭제에 실패했습니다.')
    }
  }

  render() {
    return (
      <div>
        <p className='mb-7'>SSP에 등록된 전체 유저 목록입니다.</p>
        {/*<div className='mv-7'>*/}
        {/*  <Link to={'/people/user/create'}>*/}
        {/*    <Button primary>유저 등록</Button>*/}
        {/*  </Link>*/}
        {/*</div>*/}
        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1}>#</Table.HeaderCell>
              <Table.HeaderCell width={3}>이름</Table.HeaderCell>
              <Table.HeaderCell width={4}>이메일</Table.HeaderCell>
              <Table.HeaderCell width={3}>소속</Table.HeaderCell>
              <Table.HeaderCell width={3}>가입일</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {
              this.state.users.map((user, idx) =>
                <Table.Row key={idx}>
                  <Table.Cell>{idx + 1}</Table.Cell>
                  <UserViewModal
                    userInfo={user}
                    trigger={<Table.Cell>
                      <a className='color-main pointer'>
                        {user.name}
                      </a>
                    </Table.Cell>}
                  />
                  <Table.Cell>
                    {user.email}
                  </Table.Cell>
                  <Table.Cell>
                    {user.group && user.group.name}
                  </Table.Cell>
                  <Table.Cell>
                    {moment(user.created_at).format('YYYY년 MM월 DD일 HH:mm')}
                  </Table.Cell>
                </Table.Row>
              )
            }
          </Table.Body>
        </Table>
      </div>
    )
  }
}

export default UserHome;
