import AWS from 'aws-sdk'
import md5 from 'md5'
const S3_BUCKET = process.env.REACT_APP_S3_BUCKET_NAME;
const REGION = process.env.REACT_APP_S3_REGION;
const DIST_URL = process.env.REACT_APP_S3_CF_DIST_URL;

AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
})

const myBucket = new AWS.S3({
    params: {Bucket: S3_BUCKET},
    region: REGION,
})

export const S3UploadSvg = async (file, directory) => {
    const fileRefined = directory + "/" + file.name;

    const params = {
        Body: file,
        Bucket: S3_BUCKET,
        ContentType: 'image/svg+xml',
        Key: fileRefined,
    };

    try {
        await myBucket.putObject(params).promise();

        return {
            status: 1,
            file: fileRefined,
            url: DIST_URL + "/" + fileRefined
        };
    } catch (err) {
        // console.log(err)
        return {
            status: 0,
            url: null,
            msg: "업로드에 실패했습니다."
        };
    }
}

export const S3UploadFile = async (file, directory) => {
    var _fileLen = file.name.length;
    var _lastDot = file.name.lastIndexOf('.');
    var _fileExt = file.name.substring(_lastDot, _fileLen).toLowerCase();

    const fileRefined = directory + "/" + md5(file.name) + _fileExt;

    const params = {
        Body: file,
        Bucket: S3_BUCKET,
        Key: fileRefined,
    };

    try {
        await myBucket.putObject(params).promise();

        return {
            status: 1,
            file: fileRefined,
            url: DIST_URL + "/" + fileRefined
        };
    } catch (err) {
        // console.log(err)
        return {
            status: 0,
            url: null,
            msg: "업로드에 실패했습니다."
        };
    }
}

export const S3UploadFileByFullName = async (file, directory) => {
    const fileRefined = directory + "/" + file.name;

    const params = {
        Body: file,
        Bucket: S3_BUCKET,
        Key: fileRefined,
    };

    try {
        await myBucket.putObject(params).promise();

        return {
            status: 1,
            file: file.name,
            url: DIST_URL + "/" + directory + "/" + window.encodeURIComponent(file.name)
        }
    } catch (err) {
        // console.log(err)
        return {
            status: 0,
            url: null,
            msg: "업로드에 실패했습니다."
        };
    }
}

export const S3DeleteFile = async (filePath) => {

    const params = {
        Bucket: S3_BUCKET,
        Key: window.decodeURIComponent(filePath), // decode URI into Korean
    };

    try {
        await myBucket.deleteObject(params).promise();
    } catch (err) {
        console.log(err)
    }
}