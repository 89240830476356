import React, {Component} from "react";
import {
  Button,
  Card, CardContent, Form,
  Icon, Image,
  TextArea,
} from "semantic-ui-react";
import StepperTitle, {StepperContent} from "../widgets/Stepper";
import DetailMatchedCard from "../widgets/detail.matched.card";
import DetailMsgAdmin from "../widgets/detail.msg.admin";
import axios from "axios";
import moment from "moment";
import RoundProfile from "../../../../utills/profile";

class MatchedDetail extends Component {

  state = {progress: [], adminMsg: ''}

  async getProgress() {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/bizday/progress/${this.props.match.params.matchId}`, {withCredentials: true});
      this.setState({
        progress: res.data,
        adminMsg: ''
      })
    } catch (e) {
      console.log(e);
    }
  }

  async componentDidMount() {
    this.getProgress();
  }

  handleAdminMsg = (e, data) => {
    this.setState({
      adminMsg: data.value
    })
  }

  handleProgress = async (msg, statusCode, cType, extra) => {
    if (msg.trim() === '') {
      alert("내용이 없습니다.");
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/bizday/progress`, {
        'matched_id': this.props.match.params.matchId,
        'msg': msg,
        'status_code': statusCode,
        'creator_type': cType,
        'extra': extra ?? ''
      }, {withCredentials: true});
      await this.getProgress();
    } catch (e) {
      alert("진행 상태 변경에 실패했습니다.");
    }
  }

  render() {
    return (
      <div>
        <h1>매칭된 니즈</h1>

        <div className='pt-5'>

          {
            this.state.progress.map((p, idx) =>
              <div key={idx}>
                <StepperTitle title={p.title} step={p.step}/>
                <StepperContent>
                  {
                    p.progress.map((m, idx) => {
                      if (m.message_type === 'MATCHED_NEEDS') {
                        return (
                          <div>
                            <div className='p-card pd-1 mb-5'>
                              <p>{JSON.parse(m.message).msg}</p>
                            </div>

                            <DetailMatchedCard vNeeds={m.matched_needs.venture_needs} pNeeds={m.matched_needs.partner_needs}/>
                          </div>
                        );
                      }
                      else if (m.message_type === 'FILE_UPLOAD') {
                        return null;
                      }
                      else if (m.message_type === 'FILE_DOWNLOAD') {
                        return (
                          <div className='p-card pd-1 mb-5'>
                            <p className='mb-5'>{JSON.parse(m.message).title}</p>
                            <p className='mb-5 fs-1 fw-b'>{JSON.parse(m.message).description}</p>
                            <div className='is-flex' style={{justifyContent: 'center', flexWrap: 'wrap'}}>
                              <a href={JSON.parse(m.message).file} className='has-text-centered hover-gray no-drag mh-1 mb-1'
                                 style={{color: 'gray', borderRadius: '10px', padding: '0.5rem 1rem', cursor: 'pointer'}}>
                                <Icon name='cloud download'/> PDF 다운로드
                              </a>
                            </div>
                          </div>
                        )
                      }
                      else { // Default
                        if (m.creator_type === 'BOT') {
                          return (
                            <div className='p-card pd-1 mb-5'>
                              <p>{JSON.parse(m.message).msg}</p>
                            </div>
                          )
                        } else if (m.creator_type === 'ADMIN') {
                          return (
                            <DetailMsgAdmin content={JSON.parse(m.message).msg} date={moment(m.updated_at).format('YYYY년 MM월 DD일 HH:mm')}/>
                          )
                        } else {
                          return (
                            <div style={{display: 'flex'}} className='mv-5'>
                              <div style={{flexShrink: 0}}>
                                <RoundProfile src={m.profile.profile_url} size={40}/>
                              </div>
                              <div className='p-card pd-1 ml-5' style={{marginTop: 0, borderRadius: '20px'}}>
                                <p>{JSON.parse(m.message).msg}</p>
                                <p style={{color: 'gray', fontSize: '0.9em'}}>{moment(m.updated_at).format('YYYY년 MM월 DD일 HH:mm')}</p>
                              </div>
                            </div>
                          )
                        }
                      }
                    })
                  }
                  {
                    this.state.progress.length === idx + 1 && <div className='mv-5'>
                      <CardContent>
                        <Form className='mb-5'>
                          <TextArea fluid placeholder='전달할 내용을 입력해주세요.' onChange={this.handleAdminMsg} value={this.state.adminMsg}/>
                        </Form>
                      </CardContent>
                      <Button fluid content='올리기' primary onClick={() => this.handleProgress(this.state.adminMsg, idx + 1, 'ADMIN')}/>
                    </div>
                  }
                  {
                    this.state.progress.length === idx + 1 && this.state.progress.length === 1 && <div>
                      <Button onClick={() => this.handleProgress('⏳ 벤처의 사업 제안서 제출을 기다리고 있습니다.', 2, 'BOT', 'FILE_UPLOAD_REQUIRED')} className='mt-5' basic content="'벤처 사업계획 제안' 단계로 넘어가기" labelPosition='right' icon='arrow right'/>
                    </div>
                  }
                  {
                    this.state.progress.length === idx + 1 && this.state.progress.length === 2 && <div>
                      <Button onClick={() => this.handleProgress('활용부서 검토 중입니다.', 2, 'BOT')} className='mt-5' basic content="활용부서 검토중입니다."/>
                      <Button onClick={() => this.handleProgress('🤝 미팅을 진행합니다.', 3, 'BOT')} className='mt-5' basic content="'할용부서 & 밴처 미팅' 단계로 넘어가기" labelPosition='right' icon='arrow right'/>
                    </div>
                  }
                  {
                    this.state.progress.length === idx + 1 && this.state.progress.length === 3 && <div>
                      <Button onClick={() => this.handleProgress('제품 시연 및 검증 진행 중입니다.', 3, 'BOT')} className='mt-5' basic content="제품 시연 및 검증 진행 중입니다."/>
                      <Button onClick={() => this.handleProgress('📝 협업성과 합의내용 작성 중입니다.', 4, 'BOT')} className='mt-5' basic content="'협업성과 창출합의' 단계로 넘어가기" labelPosition='right' icon='arrow right'/>
                    </div>
                  }
                  {
                    this.state.progress.length === idx + 1 && this.state.progress.length === 4 && <div>
                      <Button onClick={() => this.handleProgress('🎁 Biz Day 성과 게시물 작성 중입니다.', 5, 'BOT')} className='mt-5' basic content="'성과홍보' 단계로 넘어가기" labelPosition='right' icon='arrow right'/>
                    </div>
                  }
                  {
                    this.state.progress.length === idx + 1 && this.state.progress.length === 5 && <div>
                      <Button onClick={() => this.handleProgress('Biz Day 성과 게시물이 등록되었습니다.', 5, 'BOT')} className='mt-5' basic content="Biz Day 성과 게시물이 등록되었습니다."/>
                      <Button onClick={() => this.handleProgress('📈 사후관리를 시작합니다.', 6, 'BOT')} className='mt-5' basic content="'사후관리' 단계로 넘어가기" labelPosition='right' icon='arrow right'/>
                    </div>
                  }
                  {
                    this.state.progress.length === idx + 1 && this.state.progress.length === 6 && <div>
                      <Button onClick={() => this.handleProgress('🥳 판매 매출이 발생했습니다!', 6, 'BOT')} className='mt-5' basic content="🥳 판매 매출이 발생했습니다!"/>
                    </div>
                  }
                </StepperContent>
              </div>
            )
          }
        </div>

      </div>
    )
  }
}

export default MatchedDetail;