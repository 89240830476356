import React, {Component} from "react";
import {Button, Divider, Grid, Icon} from "semantic-ui-react";
import {Link} from "react-router-dom";
import axios from "axios";

class BizdaySelect extends Component {
  state = {bizGroup: []}

  async componentDidMount() {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/bizday/group`, {withCredentials: true});
    this.setState({
      bizGroup: res.data
    });
  }

  render() {

    return (
      <div>
        <h1>비즈데이</h1>
        <p>관리할 Biz Day를 선택하거나, 새로운 비즈데이 그룹을 추가할 수 있습니다.</p>
        <Link to={'/program/bizday/new-bizday-group'}>
          <Button primary className='mt-5 mb-8'><Icon name='add'/>새로운 그룹 추가</Button>
        </Link>
        <Grid doubling columns={4}>
          {this.state.bizGroup.map((biz, idx) =>
            <Grid.Column key={idx}>
              <Link to={`/program/bizday/${biz.slug}`}>
                <div className='p-card hover-zoom'>
                  <div className='pd-1'>
                    <p style={{fontWeight: 'bold', fontSize: '1.3em', color: '#000000DE'}}>{biz.name} <span
                      style={{color: '#005386', float: 'right'}}><Icon
                      name='arrow circle right'/></span></p>
                  </div>
                  <div className='p-box-1by1'>
                    <div className='p-box-ch is-flex is-vcentered'>
                      <div className='pd-1' style={{width: '100%'}}>
                        <img style={{width: '100%', maxHeight: '300px'}} src={biz.thumbnail_url}/>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </Grid.Column>
          )}
        </Grid>
      </div>
    )
  }
}

export default BizdaySelect;