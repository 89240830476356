import React, {Component} from "react";

export default class Footer extends Component {
  render() {
    return (
      <div className='pv-8'>
        <p style={{textAlign: 'center', padding: '1rem', color: '#878890', fontSize: '0.9em'}}>Developed by <a href={'https://poapper.com'} style={{color: '#878890'}}>PoApper, Inc.</a></p>
      </div>
    );
  }
}