import {Component} from "react";
import {Button, Label} from "semantic-ui-react";
import moment from "moment";

export default class ProgramYoutubeCard extends Component {
  render() {
    return (
      <div className='p-card'>
        <div className='p-box-16by9'>
          <img className='p-box-ch' src={`https://img.youtube.com/vi/${this.props.video.youtube_id}/0.jpg`} style={{objectFit:'cover'}}/>
        </div>
        <div className='pd-1'>
          <p style={{color: '#000000DE', fontWeight: 'bold'}}>{this.props.video.title}</p>
          <p style={{color: 'gray'}}>{this.props.video.login_required ? <Label>로그인 필요</Label> : null} {moment(this.props.video.created_at).format('YYYY.MM.DD.')}</p>
          <Button negative onClick={this.props.delete}>삭제</Button>
        </div>
      </div>
    );
  }
}