import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Divider } from 'semantic-ui-react'
import CitizenHome from "./citizen.home";
import CitizenCreate from "./citizen.create";
import CitizenUpdate from "./citizen.update";
import CitizenCategory from "./citizen.category";

class CitizenModule extends Component {

  render () {
    return (
      <div>
        <h1>기업시민 활동</h1>
        <p className='mb-7'>더불어 함께 발전하는 기업시민 POSCO</p>
        <Switch>
          <Route exact path={'/board/citizen'} component={CitizenHome}/>
          <Route exact path={'/board/citizen/create'} component={CitizenCreate}/>
          <Route path={'/board/citizen/update'} component={CitizenUpdate}/>
          <Route exact path={'/board/citizen/category'} component={CitizenCategory}/>
        </Switch>
      </div>
    )
  }
}

export default CitizenModule;
