import React, {Component} from 'react';
import axios from "axios";
import {Modal, Buttonz, Button, Image, Icon, Divider} from "semantic-ui-react";
import moment from "moment";
import RoundProfile from "../../utills/profile";

class UserViewModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    }
  }

  // handleDelete = async () => {
  //   try {
  //     await axios.delete(
  //       `${process.env.REACT_APP_API_URL}/user/${this.params.uuid}`)
  //     alert('유저를 삭제했습니다!')
  //     window.location.href = '/people/user'
  //   } catch (e) {
  //     alert('유저 삭제에 실패했습니다.')
  //   }
  // }

  render() {
    const userInfo = this.props.userInfo;
    return (
      <Modal
        onClose={() => this.setState({open: false})}
        onOpen={() => this.setState({open: true})}
        open={this.state.open}
        trigger={this.props.trigger}
      >
        <Modal.Header>유저 정보</Modal.Header>
        <Modal.Content style={{textAlign: "center"}}>
          <RoundProfile src={userInfo.profile_url} size={150}/>
          <h3 className='mt=5 mb-3'>
            {userInfo.name}
          </h3>
          <p style={{color: "grey"}}>
            {userInfo.group && `${userInfo.group.name} | `}{userInfo.user_type}
          </p>
          <p style={{color: "grey", marginBottom: "4px"}}>
            <Icon name='mail'/>{userInfo.email}
          </p>
          <Divider style={{margin: "15px 40px"}}/>
          <p style={{color: "grey", marginBottom: "4px"}}>
            {moment(userInfo.created_at).format("YYYY년 MM월 DD일 HH:mm")}에 가입
          </p>
          <p style={{color: "grey"}}>
            {moment(userInfo.last_login_at).format("YYYY년 MM월 DD일 HH:mm")}에 마지막으로 로그인
          </p>
        </Modal.Content>
        {/*<Modal.Actions>*/}
        {/*  <Button content="유저 삭제" icon='trash' negative onClick={this.handleDelete}/>*/}
        {/*</Modal.Actions>*/}
      </Modal>
    );
  }
}

export default UserViewModal;
