import React, { Component } from "react";
import { Button, Form, Icon } from "semantic-ui-react";
import ActivityUpdate from "../widgets/activity.update";
import ActivityNotice from "../widgets/activity.notice.manage";
import ActivityGroup from "../widgets/activity.group.manage";

export default class ActivityDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0,
      id : this.props.match.params.id
    };
  }

  handleTabs = () => {
    console.log('hihihi')
    if(this.state.currentTab == 0) {
      return <ActivityUpdate id={this.state.id}/>
    }
    if(this.state.currentTab == 1) {
       return <ActivityGroup id={this.state.id}/>
      
    }
    if(this.state.currentTab == 2) {
      return <ActivityNotice id={this.state.id}/>
      
    }
  }

  render() {
    const buttonList = [
      {
        name: '액티비티 관리',
        icon: 'exchange'
      },{
        name: '참여 그룹 및 알림 관리',
        icon: 'user'
      },{
        name: '공지 관리',
        icon: 'clipboard list'
      },]

    return (
      <>
        <h1>SSP 액티비티 관리</h1>
        <p>
          참가 유저, 공지, 액티비티 등을 추가, 삭제, 수정할 수 있습니다. 현재
          참가한 유저들에게 이메일을 보낼 수도 있습니다.
        </p>
        <div>
        {buttonList.map((button,idx) => {
          return (
            <Button primary={idx == this.state.currentTab ? true : false} onClick={() => {this.setState({currentTab: idx})}} className="mr-6 mb-6">
             <Icon name={button.icon} />
             {button.name}
           </Button>)
          })
          }
       
        </div>
        <div className="pd-2 p-card">
        {this.handleTabs()}
                 
        </div>
      </>
    );
  }
}
