import React, { Component } from 'react'
import axios from 'axios'
import { Form } from 'semantic-ui-react'
import { DateTimeInput } from 'semantic-ui-calendar-react'

class SurveyCreate extends Component {
  constructor (props) {
    super(props)
    this.state = {
      startAt: '',
      endAt: '',
    }
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value,
    })
  }

  handleSave = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/board/survey`, {
        'title': this.state.title,
        'description': this.state.description,
        'link_url': this.state.link_url,
        'start_at': this.state.start_at,
        'end_at': this.state.end_at,
      }, {withCredentials: true})
      alert('설문조사를 생성했습니다!')
      window.location.href = '/board/survey'
    } catch (e) {
      alert('설문조사 저장에 실패했습니다.')
    }
  }

  render () {
    return (
      <div>
        <h3>설문조사 생성</h3>
        <Form>
          <Form.Input required label='설문조사 제목' name='title'
                      placeholder="제목을 입력해주세요." onChange={this.handleChange}/>
          <Form.TextArea required label='설문조사 설명' name='description'
                         placeholder="설명을 입력해주세요."
                         onChange={this.handleChange}/>
          <Form.Input required label='설문조사 링크' name='link_url'
                      placeholder="구글 설문지 링크를 입력해주세요."
                      onChange={this.handleChange}/>
          <div className={'required field'}>
            <label>설문 기간</label>
            <Form.Group required>
              <DateTimeInput name='start_at' placeholder="설문 시작"
                             dateFormat='YYYY-MM-DD'
                             value={this.state.start_at}
                             onChange={this.handleChange}/>
              <DateTimeInput name='end_at' placeholder="설문 종료"
                             dateFormat='YYYY-MM-DD'
                             value={this.state.end_at}
                             onChange={this.handleChange}/>
            </Form.Group>
          </div>
          <br/>
          <Form.Button onClick={this.handleSave}>저장</Form.Button>
        </Form>
      </div>
    )
  }

}

export default SurveyCreate;