import React, {Component} from "react";
import {Grid, Loader} from "semantic-ui-react";
import axios from "axios";
import InvestNeedsCard from "../widgets/invest.needs.card";

export default class FestivalVentureTab extends Component {
  state = {isLoaded: false, needs: []};

  async componentDidMount() {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/invest/festival/${this.props.festivalId}/needs`, {withCredentials: true});
    this.setState({
      needs: res.data,
      isLoaded: true,
    });
  }

  render() {
    return (
      <>
        {
          !this.state.isLoaded && <Loader active inline='centered'/>
        }
        {
          this.state.isLoaded && this.state.needs.length === 0 && <p className='color-gray pd-1'>참여 신청한 투자 니즈가 없습니다.</p>
        }
        <Grid columns={3} doubling stackable>
          {
            this.state.needs.map((needs) => <Grid.Column>
              <InvestNeedsCard needs={needs.inv_needs} opinion={needs.venture_opinion}>
                <p className='color-gray'
                   style={{border: '1px solid #eaeaea', borderRadius: '10px', padding: '0.5rem'}}>
                  {needs.venture_opinion}
                </p>
              </InvestNeedsCard>
            </Grid.Column>)
          }
        </Grid>
      </>
    );
  }
}