import React, {Component} from "react";
import {Grid} from "semantic-ui-react";
import NeedsCard from "../widgets/needs.card";

export default class NeedsTab extends Component {
  render() {
    return (
      <div>
        {
          this.props.needs.length === 0 ? <p className='color-gray pd-1'>등록된 니즈가 없습니다.</p> : null
        }
        <Grid columns={3} doubling stackable>
          {
            this.props.needs.map((needs) =>
              <Grid.Column>
                <NeedsCard needs={needs}/>
              </Grid.Column>
            )
          }
        </Grid>
      </div>
    );
  }
}