import React, {Component} from 'react';
import axios from "axios";
import {Button, Table} from "semantic-ui-react";
import {Link} from "react-router-dom";

class HomeNewUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: []
    }
  }

  async componentDidMount() {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/statistics/latest/login/user?take=10`);
      this.setState({
        users: res.data
      })
    } catch (e) {
      alert("그룹 정보 로딩에 실패했습니다.");
    }
  }

  render() {
    return (
      <div>
        <div style={{display: 'flex'}}>
          <h3 style={{flexGrow: 1}}>최근 접속 유저</h3>
          <Link to="/people/user">
            <Button style={{backgroundColor: "#006db4", color: "white"}} content='전체보기' icon='right arrow'
                    labelPosition='right'/>
          </Link>
        </div>
        <Table celled selectable color='blue' compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={3}>이름</Table.HeaderCell>
              <Table.HeaderCell width={5}>소속</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              this.state.users.map((user, idx) =>
                <Table.Row key={idx}>
                  <Table.Cell>
                    {user.name}
                  </Table.Cell>
                  <Table.Cell>
                    {user.group_name ?? ''}
                  </Table.Cell>
                </Table.Row>
              )
            }
          </Table.Body>
        </Table>
      </div>
    );
  }

}

export default HomeNewUser;