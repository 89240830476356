import React, {Component} from "react";
import axios from "axios";
import {Button, Container, Divider, Form} from "semantic-ui-react";

class GroupCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      subtype: []
    }
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  async componentDidMount() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/group/meta`);
      const meta = response.data;
      const groupTypeOptions = Object.entries(meta.group_type).map((type) => {
        const [key, value] = type;
        return {
          key: key,
          text: value,
          value: value
        }
      });

      const groupSubtypeOptions = meta.group_subtype.map((type) => {
        return {
          key: type.id,
          text: `[${type.parent_type}] ${type.name}`,
          value: type.id
        }
      });

      this.setState({
        isLoaded: true,
        groupTypeOptions: groupTypeOptions,
        groupSubtypeOptions: groupSubtypeOptions
      });
    } catch (e) {
      alert("그룹 메타 정보 로딩에 실패했습니다.");
    }
  }

  handleSubmit = async (e) => {
    const emailVal = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,5}$/;
    if (!emailVal.test(this.state.admin_email)) {
      alert('관리자 이메일이 올바르지 않습니다.')
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/admin/group/manage`, {
        'name': this.state.name,
        'type': this.state.type,
        'subtype': this.state.subtype,
        'admin_email': this.state.admin_email,
        'admin_name': this.state.admin_name,
      }, {withCredentials: true});
      alert('그룹을 생성했습니다!')
      window.location.href = '/people/group'
    } catch (err) {
      const response = err.response;
      alert(`그룹 생성에 실패했습니다.\n${response.data.message}`);
    }
  }

  render() {
    return (
      <>
        <h3 className='mt-8'>그룹 추가</h3>
        <p className='mb-8'>SSP에 새로운 그룹을 추가합니다. 그룹 기본 정보와 대표자 정보를 모두 입력한 뒤 생성하기 버튼을 눌러주세요.</p>

        {this.state.isLoaded ?
          <Form onSubmit={this.handleSubmit}>
            <Form.Input required label='그룹 이름(Group Name)' name='name'
                        placeholder='법인명(단체명)을 입력해주세요.'
                        onChange={this.handleChange}/>
            {/*<Form.Input required label='그룹 슬러그(Slug)' name='slug'*/}
            {/*            placeholder='URL을 생성할 슬러그를 입력해주세요. (영어 소문자 & 숫자만 가능)'*/}
            {/*            onChange={this.handleChange}/>*/}
            <Form.Dropdown required fluid selection
                           label='그룹 타입(Type)' name='type' options={this.state.groupTypeOptions}
                           placeholder='그룹 타입을 선택해주세요.'
                           onChange={this.handleChange}/>
            <Form.Dropdown fluid multiple selection
                           label='그룹 서브타입(Subtype)' name='subtype' options={this.state.groupSubtypeOptions}
                           placeholder='그룹 서브타입을 선택해주세요.'
                           onChange={this.handleChange}
                           value={this.state.subtype}/>
            <Form.Input className='mt-8' required label='대표자 이메일(Email)' name='admin_email'
                        placeholder='그룹을 관리하는 대표자(관리자)의 이메일을 입력해주세요.'
                        onChange={this.handleChange}/>
            <Form.Input required label='대표자 이름' name='admin_name'
                        placeholder='대표자(관리자)의 이름을 입력해주세요.'
                        onChange={this.handleChange}/>
            <Button type='submit' className='mv-7'>생성하기</Button>
          </Form>
          : null}

      </>
    );
  }
}

export default GroupCreate;