import React, {Component} from "react";
import {Tab} from "semantic-ui-react";
import AlwaysVentureTab from "./tabs/always.venture.tab";
import AlwaysVCTab from "./tabs/always.vc.tab";
import AlwaysMatchedTab from "./tabs/always.matched.tab";

class InvestAlwaysPage extends Component {
  render() {
    const panes = [
      {
        menuItem: '매칭된 투자 니즈',
        render: () => <AlwaysMatchedTab/>,
      },
      {
        menuItem: '모든 벤처 니즈',
        render: () => <AlwaysVentureTab/>,
      },
      {
        menuItem: '투자자 매칭 요청',
        render: () => <AlwaysVCTab/>,
      },
    ];

    return (
      <div className='p-container'>
        <h1>상시 모집</h1>
        <p className='color-gray mb-6'>
          벤처들의 모든 투자 니즈들을 확인하고 매칭을 원하는 투자자와 상시 매칭을 할 수 있습니다.
        </p>
        <Tab id='biz-tap' menu={{secondary: true}} panes={panes}/>
      </div>
    );
  }
}

export default InvestAlwaysPage;