import React, {Component} from "react";
import {Button, Form} from "semantic-ui-react";
import axios from "axios";
import {DateTimeInput} from "semantic-ui-calendar-react";
import moment from "moment";

class UpdateInvestFestival extends Component {
  state = {groups: [], host_groups: []};

  async componentDidMount() {
    const festival = await axios.get(`${process.env.REACT_APP_API_URL}/invest/festival/${this.props.match.params.uuid}`, {withCredentials: true});
    const group = await axios.get(`${process.env.REACT_APP_API_URL}/invest/invest-group`, {withCredentials: true});
    this.setState({
      groups: group.data.map((group) => {
        return {
          key: group.uuid,
          text: group.name,
          value: group.uuid,
          description: group.type,
          image: {avatar: true, src: group.profile_url ?? 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'}
        };
      }),
      host_groups: festival.data.host_groups.map((group) => group.uuid),
      title: festival.data.title,
      description: festival.data.description,
      start_at: festival.data.start_at ,
      end_at: festival.data.end_at,
      status: festival.data.status
    });
  }

  toggle = () => this.setState((prevState) => ({status: !prevState.status}));
  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  updateInvestFestival = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/invest/${this.props.match.params.uuid}`, {
        'host_groups': this.state.host_groups,
        'title': this.state.title,
        'description': this.state.description,
        'start_at': this.state.start_at,
        'end_at': this.state.end_at,
        'status': this.state.status
      }, {withCredentials: true});
      window.location.replace(`/investment`);
    } catch (e) {
      console.log(e);
      alert("투자 행사 수정에 실패했습니다.");
    }
  }

  render() {
    const renderLabel = (label) => ({
      content: `${label.text}`,
    })

    return (
      <div>
        <h1>행사 내용 수정</h1>
        <p className='mb-8'>투자 행사 내용을 수정합니다.</p>
        <Form>
          <Form.Input required label='행사 제목' name='title' placeholder="행사 제목을 입력해주세요."
                      onChange={this.handleChange} value={this.state.title}/>
          <Form.TextArea rows={10} label='행사 설명' name='description' placeholder="행사 설명을 적어주세요. (Ex: 주제, 참고사항, 문의처 등)"
                         onChange={this.handleChange} value={this.state.description}/>

          <Form.Dropdown
            required
            label='참여 투자자'
            placeholder='행사 호스트인 투자자 그룹을 모두 선택해주세요.'
            fluid
            search
            selection
            multiple
            options={this.state.groups}
            renderLabel={renderLabel}
            value={this.state.host_groups}
            name='host_groups' onChange={this.handleChange}
          />

          <div className={'required field'}>
            <label>신청 기간</label>
            <Form.Group required>
              <DateTimeInput name='start_at' placeholder="시작일"
                             dateFormat='YYYY-MM-DD'
                             value={moment(this.state.start_at).format('YYYY-MM-DD HH:mm')}
                             onChange={this.handleChange}/>
              <DateTimeInput name='end_at' placeholder="종료일"
                             dateFormat='YYYY-MM-DD'
                             value={moment(this.state.end_at).format('YYYY-MM-DD HH:mm')}
                             onChange={this.handleChange}/>
            </Form.Group>
          </div>

          <div className='ui field is-flex'>
            <label>신청 접수 받기</label>
            <Form.Checkbox className='ml-5' toggle name='status' onChange={this.toggle} checked={this.state.status}/>
          </div>

          <Button className='mv-7' primary onClick={this.updateInvestFestival}>수정</Button>
        </Form>
      </div>
    )
  }
}

export default UpdateInvestFestival;