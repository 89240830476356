import React, {Component} from "react";
import {Button, Form, Icon, Modal} from "semantic-ui-react";
import axios from "axios";
import GroupUser from "../../../components/group/group.user";
import moment from "moment";
import RoundProfile from "../../../utills/profile";

class GroupUuid extends Component {
  state = {open: false}
  open = () => this.setState({open: true})
  close = () => this.setState({open: false})

  constructor(props) {
    super(props);
    this.params = this.props.match.params;
    this.state = {
      checked: false,
      isLoaded: false,
    }
  }

  toggle = () => this.setState((prevState) => ({ checked: !prevState.checked }))

  async componentDidMount() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/group/${this.params.uuid}/admin`, {withCredentials: true});
      const group = response.data;

      this.setState({
        group: group,
        name: group.name,
        type: group.type,
        checked: group.is_certified,
        subtype: group.subtype.map((subtype) => {
          return subtype.id
        }),
        users: group.users,
        channel_info: group.channel_info
      });
    } catch (e) {
      alert("그룹 정보 로딩에 실패했습니다.");
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/group/meta`);
      const meta = response.data;
      const groupTypeOptions = Object.entries(meta.group_type).map((type) => {
        const [key, value] = type;
        return {
          key: key,
          text: value,
          value: value
        }
      });

      const groupSubtypeOptions = meta.group_subtype.map((type) => {
        return {
          key: type.id,
          text: `[${type.parent_type}] ${type.name}`,
          value: type.id
        }
      });

      this.setState({
        isLoaded: true,
        groupTypeOptions: groupTypeOptions,
        groupSubtypeOptions: groupSubtypeOptions
      });
    } catch (e) {
      alert("그룹 메타 정보 로딩에 실패했습니다.");
    }
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value,
    })
  }

  handleSubmit = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/group/${this.params.uuid}`, {
          'name': this.state.name,
          'slug': this.state.slug,
          'type': this.state.type,
          'is_certified': this.state.checked,
          'subtype': this.state.subtype,
          'field': [],
        }, {withCredentials: true})
      alert('그룹 정보를 수정했습니다!')
    } catch (e) {
      alert('그룹 수정에 실패했습니다.')
    }
  }

  handleDelete = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/group/${this.params.uuid}`, {withCredentials: true})
      alert('그룹을 삭제했습니다!')
      window.location.href = '/people/group'
    } catch (e) {
      alert('그룹 삭제에 실패했습니다.')
    }
  }

  async createNewChannel() {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/channel/admin/create-new-channel`, {
          'groupId': this.params.uuid,
        }, {withCredentials: true})
      alert('그룹 채널을 생성했습니다.')
      window.location.reload();
    } catch (e) {
      console.log(e);
      alert('채널 생성에 실패했습니다.');
    }
  }

  render() {
    return (
      <div>
        <h3 className='mt-8'>그룹 정보 조회 & 수정</h3>
        <p className='mb-8'>그룹 기본 정보를 수정할 수 있습니다. 변경 후 '정보 수정' 버튼을 눌러주세요.</p>
        {this.state.isLoaded ?
          <div>
            <div className='is-flex has-item-centered mt-8 mb-5'>
              <RoundProfile src={this.state.group.profile_url} size={100}/>
            </div>

            <Form>
              <Form.Input required label='그룹 이름' name='name'
                          placeholder="그룹 이름을 입력해주세요."
                          onChange={this.handleChange}
                          value={this.state.name}/>
              <Form.Select required fluid selection
                           label='그룹 타입(Type)' name='type' options={this.state.groupTypeOptions}
                           placeholder='그룹 타입을 선택해주세요.'
                           onChange={this.handleChange}
                           value={this.state.type}/>
              <Form.Dropdown fluid multiple selection
                             label='그룹 서브타입(Subtype)' name='subtype' options={this.state.groupSubtypeOptions}
                             placeholder='그룹 서브타입을 선택해주세요.'
                             onChange={this.handleChange}
                             value={this.state.subtype}/>

              {
                this.state.group.type !== 'ADMIN' &&
                <div className='ui field is-flex is-vcentered'>
                  <label className='mr-5'>그룹 채널 인증</label>
                  <Form.Checkbox
                    value={this.state.checked}
                    onChange={this.toggle}
                    checked={this.state.checked}
                  />
                </div>
              }

              <div className='mv-8'>
                <p className='color-gray'>그룹 생성일: {moment(this.state.group.created_at).format('YYYY-MM-DD HH:mm')}</p>
                <p className='color-gray'>그룹 ID: {this.state.group.uuid}</p>
              </div>

              <Form.Group>
                <Form.Button primary onClick={this.handleSubmit}>
                  <Icon name='edit'/> 정보 수정
                </Form.Button>
                <Modal
                  size={'mini'}
                  onClose={() => this.close()}
                  onOpen={() => this.open()}
                  open={this.state.open}
                  trigger={
                    <Button negative
                            content='삭제'
                            onClick={this.open}
                    ><Icon name='trash'/> 삭제</Button>
                  }
                >
                  <Modal.Header>그룹 삭제</Modal.Header>
                  <Modal.Content>
                    <p>그룹 정보와 해당 그룹에 속한 모든 유저, 채널, 영상, 커뮤니티, 상품 등 모든 내용이 지워집니다.<br/><br/>
                      <b>한번 지워진 그룹은 복구할 수 없습니다.</b><br/><br/>
                      그룹을 삭제 하시겠습니까?
                    </p>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button onClick={() => this.close()}>
                      취소
                    </Button>
                    <Button
                      negative
                      content="삭제"
                      onClick={this.handleDelete}
                    />
                  </Modal.Actions>
                </Modal>
              </Form.Group>

            </Form>
            {
              this.state.group.type !== 'ADMIN' && <div>
                <h3 className='mt-8'>그룹 채널 관리</h3>
                {
                  this.state.channel_info ? <div className='p-card pd-1 is-flex'>
                      <RoundProfile src={this.state.channel_info.profile_url} size={80} slug={this.state.channel_info.slug}/>
                      <div className='ml-6'>
                        <p className='fw-b fs-1 mb-2'>{this.state.channel_info.name}</p>
                        <p className='color-gray'><Icon name={'linkify'}/>{this.state.channel_info.slug}</p>
                        <p className={'fw-b'}>구독자 수 {this.state.channel_info.subscriber_num}명</p>
                      </div>
                    </div>
                    : <div>
                      <p className='color-gray'>생성된 채널이 없습니다.</p>
                      <Button onClick={() => this.createNewChannel()}><Icon name={'add'}/> 새로운 채널 개설하기</Button>
                    </div>
                }
              </div>
            }
            <div>
              <h3 className='mt-8'>그룹에 속한 유저 목록</h3>
              <GroupUser users={this.state.users}/>
            </div>
          </div> : null
        }
      </div>
    )
  }

}

export default GroupUuid;
