import React, {Component} from "react";
import {Divider} from "semantic-ui-react";
import {Link} from "react-router-dom";
import RoundProfile from "../../../../utills/profile";

export default class MatchedNeedsCard extends Component {
  render() {
    return (
      <Link to={`/program/bizday/matched/detail/${this.props.m.uuid}`}>
        <div className='p-card pd-1 hover-zoom pointer'>
          <p className='fs-1 fw-b color-black'>{this.props.m.venture_needs.title}</p>
          <div className='is-flex is-vcentered' style={{justifyContent: "flex-end"}}>
            <RoundProfile src={this.props.m.venture_needs.group.profile_url} size={35}/>
            <p className='color-black mb-0 ml-5 fw-b'>{this.props.m.venture_needs.group.name}</p>
          </div>
          <Divider horizontal className='mv-6'><span className='color-main'>MATCHED</span></Divider>
          <p className='fs-1 fw-b color-black'>{this.props.m.partner_needs.title}</p>
          <div className='is-flex is-vcentered' style={{justifyContent: "flex-end"}}>
            <RoundProfile src={this.props.m.partner_needs.group.profile_url} size={35}/>
            <p className='color-black mb-0 ml-5 fw-b'>{this.props.m.partner_needs.group.name}</p>
          </div>
        </div>
      </Link>
    );
  }
}