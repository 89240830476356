import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import ActivityModule from "./activity/activity.module";
import BizdayModule from "./bizday/bizday.module";
import IncubatorPage from "./intro/pages/incubator.page";
import IntroPage from "./intro/pages/intro.page";
import InvestmentPage from "./intro/pages/investment.page";
import MarketPage from "./intro/pages/market.page";
import NetworkPage from "./intro/pages/network.page";
import OfficeSection from "./intro/pages/office.section";
import ProgramPage from "./intro/pages/program.page";

class ProgramModule extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route path={"/program/bizday"} component={BizdayModule} />
          <Route path={"/program/activity"} component={ActivityModule} />

          <Route exact path={"/program/intro"} component={IntroPage} />
          <Route exact path={"/program/incubator"} component={IncubatorPage} />
          <Route exact path={"/program/market"} component={MarketPage} />
          <Route
            exact
            path={"/program/investment"}
            component={InvestmentPage}
          />
          <Route exact path={"/program/network"} component={NetworkPage} />
          <Route exact path={"/program/office/:id"} component={OfficeSection} />
          <Route exact path={"/program/:type/:id"} component={ProgramPage} />
        </Switch>
      </div>
    );
  }
}

export default ProgramModule;
