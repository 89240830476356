import React, {Component} from "react";
import {Button, Form, Modal} from "semantic-ui-react";
import axios from "axios";
import {S3UploadFile} from "../../../../utills/s3-file-upload";

export default class PartnerCard extends Component {
  state = {open: false}

  constructor(props) {
    super(props)
    this.state = {
      name: props.partner.name,
      description: props.partner.description,
      profile_img_url: props.partner.profile_img_url,
      website_url: props.partner.website_url,
      gitple_id: props.partner.gitple_id,
      group_uuid: props.partner.group_uuid
    }
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  open = () => this.setState({open: true})
  close = () => this.setState({open: false})

  handleDelete = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/program/partner/${this.props.partner.uuid}`, {withCredentials: true});
      this.setState({open: false});
      alert("파트너를 삭제했습니다.");
      this.props.updated();
    } catch (e) {
      alert("파트너 삭제에 실패했습니다.");
    }
  }

  handleUpdate = async () => {
    try {
      await axios.patch(`${process.env.REACT_APP_API_URL}/program/partner/${this.props.partner.uuid}`, {
        'program_type': this.props.programType,
        'name': this.state.name,
        'description': this.state.description,
        'profile_img_url': this.state.profile_img_url,
        'website_url': this.state.website_url,
        'gitple_id': this.state.gitple_id,
        'group_uuid': this.state.group_uuid
      }, {withCredentials: true});
      alert("파트너 정보를 수정했습니다.");
      this.setState({open: false});
      this.props.updated();
    } catch (e) {
      alert("파트너 수정에 실패했습니다.");
    }
  }

  handleImageUpload = async (e) => {
    const {status, url, msg} = await S3UploadFile(e.target.files[0], "partner/profile");
    if (status === 1) {
      this.setState({
        [e.target.name]: url,
      })
    } else {
      alert(msg);
    }
  }

  render() {
    return (
      <div>
        <div className='p-card pd-1 has-item-centered hover-zoom pointer' style={{flexDirection: 'column'}} onClick={this.open}>
          <img style={{
            border: '1px solid #eaeaea',
            objectFit: 'cover',
            width: '80px',
            height: '80px',
            borderRadius: '40px'
          }} src={this.props.partner.profile_img_url ?? 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'} alt='partner profile'/>
          <p className='mt-3 color-black'>{this.props.partner.name}</p>
        </div>

        {/* 파트너 정보 업데이트 */}
        <Modal
          onClose={() => this.close()}
          onOpen={() => this.open()}
          open={this.state.open}
        >
          <Modal.Header>파트너 정보 업데이트</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form>
                <div className='has-item-centered' style={{flexDirection: 'column'}}>
                  <img style={{
                    border: '1px solid #eaeaea',
                    objectFit: 'cover',
                    width: '150px',
                    height: '150px',
                    borderRadius: '75px'
                  }} src={this.state.profile_img_url ?? 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'} alt='partner profile'/>
                  <label htmlFor="upload">
                    <span className='ui button basic mv-5'>프로필 사진 업로드</span>
                    <input name='profile_img_url' type="file" id="upload" style={{display: 'none'}} onChange={this.handleImageUpload}/>
                  </label>
                </div>

                <Form.Input
                  required label='파트너 이름' name='name'
                  placeholder="파트너 이름을 입력해주세요."
                  onChange={this.handleChange}
                  value={this.state.name}
                />
                <Form.Input
                  required label='파트너 설명' name='description'
                  placeholder="간단한 설명을 입력해주세요."
                  onChange={this.handleChange}
                  value={this.state.description}
                />
                <Form.Input
                  label='홈페이지 URL' name='website_url'
                  placeholder="홈페이지가 있다면 주소를 입력해주세요."
                  onChange={this.handleChange}
                  value={this.state.website_url}
                />
                <Form.Input
                  label='채팅 그룹 ID' name='group_uuid'
                  placeholder="채팅이 가능한 그룹이 있다면 해당 그룹 ID를 입력해주세요. ( '사용자 관리' > '그룹 관리'에서 확인할 수 있습니다. )"
                  onChange={this.handleChange} value={this.state.group_uuid}
                />
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              content="수정"
              onClick={this.handleUpdate}
            />
            <Button
              content="삭제"
              labelPosition='right'
              icon='trash'
              onClick={this.handleDelete}
              negative
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}