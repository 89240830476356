import React, {Component} from "react";
import {Grid} from "semantic-ui-react";
import HomeNewGroup from "../../components/home/home.new-group";
import HomeNewUser from "../../components/home/home.new-user";
import HomeOverview from "../../components/home/home.overview";

class Home extends Component {

  render() {
    return (
      <div>
        <h1>SSP 관리자 페이지 입니다.</h1>
        <HomeOverview/>

        <div>
          <Grid columns='equal' stackable>
            <Grid.Column>
              <HomeNewUser/>
            </Grid.Column>
            <Grid.Column>
              <HomeNewGroup/>
            </Grid.Column>
          </Grid>
        </div>
      </div>
    )
  }
}

export default Home;