import React, {Component} from "react";
import {Link} from "react-router-dom";
import RoundProfile from "../../../../../utills/profile";
import {Divider, Label} from "semantic-ui-react";

export default class InvestMatchedCard extends Component {
  render() {
    return (
      <Link to={`/investment/progress/${this.props.matched.uuid}`}>
        <div className='p-card pd-1 pointer hover-zoom'>
          <div className='is-flex is-vcentered mb-5'>
            <RoundProfile size={60} src={this.props.matched.inv_needs.group.channel_info.profile_url} slug={this.props.matched.inv_needs.group.channel_info.slug}/>
            <div className='ml-5'>
              <p className='fw-b mb-2 fs-1 color-black'>{this.props.matched.inv_needs.group.channel_info.name}</p>
              <p className='color-gray fs-m1 text-ellipsis2L'>{this.props.matched.inv_needs.group.channel_info.intro}</p>
            </div>

          </div>
          <div className='mb-5 has-item-centered' style={{flexWrap: 'wrap'}}>
            <Label className='mb-1'>{this.props.matched.inv_needs.invest_phase}</Label>
            <Label className='mb-1'>{this.props.matched.inv_needs.invest_amount}억원 이상</Label>
            <Label className='mb-1'>{(this.props.matched.inv_needs.invest_time / 100).toFixed(0)}년 {this.props.matched.inv_needs.invest_time % 100}월</Label>
          </div>
          {
            this.props.opinion && <div className='pv-3 ph-4 mb-5' style={{border: '1px solid #eaeaea', borderRadius: '10px'}}>
              <p>{this.props.opinion}</p>
            </div>
          }
          {/*<p className='fs-m1 color-gray has-text-centered'>{moment(this.props.needs.created_at).format('YYYY년 MM월 DD일')} · {this.props.needs.group.name}</p>*/}

          <Divider horizontal><span className='color-main'>MATCHED</span></Divider>

          <div className='is-flex is-vcentered'>
            <RoundProfile size={60}
                          src={this.props.matched.vc_group.profile_url}/>
            <div className='ml-5'>
              <p className='fw-b mb-0 color-black'>{this.props.matched.vc_group.name}</p>
            </div>
          </div>
        </div>
      </Link>
    )
  }
}