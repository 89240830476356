import React, {Component} from "react";

export default class RoundProfile extends Component {
  render() {
    return (
      this.props.slug ?
        <a href={`https://poscossp.com/channel/${this.props.slug}`} target='_blank'>
          <img style={{
            border: '1px solid #eaeaea',
            objectFit: 'cover',
            width: `${this.props.size}px`,
            height: `${this.props.size}px`,
            borderRadius: `${this.props.size / 2}px`
          }} src={this.props.src ?? 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'} alt='profile'/>
        </a> :
        <img style={{
          border: '1px solid #eaeaea',
          objectFit: 'cover',
          width: `${this.props.size}px`,
          height: `${this.props.size}px`,
          borderRadius: `${this.props.size / 2}px`
        }} src={this.props.src ?? 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'} alt='profile'/>
    )
  }
}