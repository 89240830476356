import React, {Component} from 'react';
import {Button, Divider, Icon, Tab} from 'semantic-ui-react'
import {Link} from 'react-router-dom'
import {CitizenList} from "./widgets/citizen.list";
import axios from "axios";

class CitizenHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      panes: []
    };
    this.getTypeList()
  }

  async getTypeList() {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/board/corpoCitizen/type/all`, {withCredentials: true});
      const panes = [
        {
          menuItem: '전체',
          render: () => <CitizenList/>,
        },
      ]

      for (const type of res.data) {
        panes.push({
          menuItem: type.title,
          render: () => <CitizenList type={type.uuid}/>,
        })
      }

      this.setState({
        panes: panes
      })
    } catch (e) {
      alert("문제가 발생했습니다. 잠시 뒤 다시 시도하거나 개발자에게 문의하세요.");
    }
  }

  render() {
    return (
      <div>
        <div className='mb-7'>
          <Link to={'/board/citizen/create'}>
            <Button primary className='mb-5 mr-5'><Icon name='edit'/>게시글 작성</Button>
          </Link>
          <Link to={'/board/citizen/category'}>
            <Button primary className='mb-5'><Icon name='cogs'/>카테고리 편집</Button>
          </Link>
        </div>
        <Tab menu={{color: 'blue', secondary: true}} panes={this.state.panes}/>
      </div>
    )
  }
}

export default CitizenHome;
