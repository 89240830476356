import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import BizdaySelect from "./pages/bizday.select";
import CreateBizdayGroup from "./pages/create.bizday.group";
import BizdayListPage from "./pages/bizday.list";
import UpdateBizdayGroup from "./pages/update.bizday.group";
import CreateBizday from "./pages/create.bizday";
import UpdateBizday from "./pages/update.bizday";
import Bizday from "./pages/bizday";
import MatchedDetail from "./pages/matched.detail";

class BizdayModule extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path={"/program/bizday"} component={BizdaySelect} />
          <Route
            exact
            path={"/program/bizday/new-bizday-group"}
            component={CreateBizdayGroup}
          />
          <Route
            exact
            path={"/program/bizday/:slug"}
            component={BizdayListPage}
          />
          <Route
            exact
            path={"/program/bizday/group-setting/:id"}
            component={UpdateBizdayGroup}
          />
          <Route
            exact
            path={"/program/bizday/:slug/:id/new-bizday"}
            component={CreateBizday}
          />
          <Route
            exact
            path={"/program/bizday/:slug/:bizId/update-bizday"}
            component={UpdateBizday}
          />
          <Route
            exact
            path={"/program/bizday/:slug/:bizId"}
            component={Bizday}
          />
          <Route
            exact
            path={"/program/bizday/matched/detail/:matchId"}
            component={MatchedDetail}
          />
        </Switch>
      </div>
    );
  }
}

export default BizdayModule;
