import React, { Component } from 'react'
import {Route, Switch} from "react-router-dom";
import InvestFestivalListPage from "./pages/invest.festival.list";
import CreateInvestFestival from "./pages/create.invest.festival";
import InvestFestivalPage from "./pages/invest.festival";
import InvestAlwaysPage from "./pages/invest.always";
import InvestMatchedDetail from "./pages/invest.matched.detail";
import UpdateInvestFestival from "./pages/update.invest.festival";

class InvestModule extends Component {
  render () {
    return (
      <Switch>
        <Route exact path={"/investment"} component={InvestFestivalListPage}/>
        <Route exact path={"/investment/new-festival"} component={CreateInvestFestival}/>
        <Route exact path={"/investment/edit-festival/:uuid"} component={UpdateInvestFestival}/>
        <Route exact path={"/investment/festival/always"} component={InvestAlwaysPage}/>
        <Route exact path={"/investment/festival/:uuid"} component={InvestFestivalPage}/>
        <Route exact path={"/investment/progress/:matchId"} component={InvestMatchedDetail}/>
      </Switch>
    )
  }
}

export default InvestModule;
