import React, {Component} from "react";
import {Button, Icon, Tab} from "semantic-ui-react";
import axios from "axios";
import FestivalVentureTab from "./tabs/festival.venture.tab";
import FestivalVCTab from "./tabs/festival.vc.tab";
import FestivalMatchedTab from "./tabs/festival.matched.tab";
import {Link} from "react-router-dom";

class InvestFestivalPage extends Component {
  state = {isLoaded: false};

  async componentDidMount() {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/invest/festival/${this.props.match.params.uuid}`, {withCredentials: true});

    this.setState({
      festival: res.data,
      isLoaded: true,
    });
  }

  render() {
    const panes = [
      {
        menuItem: '매칭된 투자 니즈',
        render: () => <FestivalMatchedTab festivalId={this.props.match.params.uuid}/>,
      },
      {
        menuItem: '참여한 벤처',
        render: () => <FestivalVentureTab festivalId={this.props.match.params.uuid}/>,
      },
      {
        menuItem: '투자자 매칭 요청',
        render: () => <FestivalVCTab festivalId={this.props.match.params.uuid}/>,
      },
    ];

    return (
      <div className='p-container'>
        {
          this.state.isLoaded &&
          <>
            <h1>{this.state.festival.title}</h1>
            <p className='color-gray mb-6'>
              {
                this.state.festival.description.split('\n').map((str) => {
                  return (<div>{str}</div>)
                })
              }
            </p>

            <Link to={`/investment/edit-festival/${this.props.match.params.uuid}`}>
              <Button primary className='mb-7'><Icon name='edit'/>행사 내용 수정</Button>
            </Link>

            <Tab id='biz-tap' menu={{secondary: true}} panes={panes}/>
          </>
        }
      </div>
    );
  }
}

export default InvestFestivalPage;