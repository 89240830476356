import React, { Component } from 'react';
import { Button, Table } from 'semantic-ui-react'
import axios from 'axios';
import moment from "moment";
import 'moment-timezone';
import { Link } from 'react-router-dom'
import {noticeTypeObj} from './notice.meta';

class NoticeHome extends Component {
  constructor (props) {
    super(props);
    moment.tz.setDefault("Asia/Seoul");
    this.state = {
      notices: []
    }

    console.log(new Date());
    console.log(moment(new Date()).format('YYYY년 MM월 DD일 HH:mm'))
  }

  async componentDidMount() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/board/notice`);
      this.setState({
        notices: response.data
      })
    } catch(e) {
      console.log(e);
    }
  }

  render () {
    return (
      <div>
        <div>
          <Link to={'/board/notice/create'}>
            <Button primary className='mb-7'>공지글 작성</Button>
          </Link>
        </div>
        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1}>#</Table.HeaderCell>
              <Table.HeaderCell width={8}>제목</Table.HeaderCell>
              <Table.HeaderCell width={2}>타입</Table.HeaderCell>
              <Table.HeaderCell width={3}>작성일</Table.HeaderCell>
              <Table.HeaderCell width={2}>조회수</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {
              this.state.notices.map((notice, idx) =>
                <Table.Row key={idx}>
                  <Table.Cell>{idx+1}</Table.Cell>
                  <Table.Cell>
                    <Link to={`/board/notice/update?uuid=${notice.uuid}`}>
                      {notice.title}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>{noticeTypeObj[notice.type]}</Table.Cell>
                  <Table.Cell>
                    {
                      console.log(notice.created_at)
                    }
                    {moment(notice.created_at).format('YYYY년 MM월 DD일 HH:mm')}
                  </Table.Cell>
                  <Table.Cell>{notice.view_num}</Table.Cell>
                </Table.Row>
              )
            }
          </Table.Body>
        </Table>
      </div>
    )
  }
}

export default NoticeHome;
