import React, {Component} from "react";
import {Button, Form, Grid, Loader, Modal} from "semantic-ui-react";
import {Editor} from "@toast-ui/react-editor";
import axios from "axios";
import ProgramYoutubeCard from "../widgets/program.youtube.card";
import {S3UploadFile} from "../../../../utills/s3-file-upload";


class ProgramPage extends Component {
  editorRef = React.createRef()

  constructor(props) {
    super(props)
    this.state = {
      open: false,
      isLoading: true,
      isVideoLoading: true,
      video: [],
      newLoginRequired: false,
    }
  }

  async componentDidMount() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/program/${this.props.match.params.id}`)
      this.getVideo();
      const program = response.data;

      this.setState({
        isLoading: false,
        title: program.title,
        section: program.section,
        intro: program.intro,
        website_url: program.website_url,
        tel: program.contact_json ? JSON.parse(program.contact_json).tel : '',
        email: program.contact_json ? JSON.parse(program.contact_json).email : '',
        gitple_id: program.contact_json ? JSON.parse(program.contact_json).gitple_id : '',
        group_uuid: program.contact_json ? JSON.parse(program.contact_json).group_uuid : ''
      });
    } catch (e) {
      console.log(e);
    }
  }

  open = () => this.setState({open: true})
  close = () => this.setState({open: false})

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  handleSave = async () => {
    const editorInstance = this.editorRef.current.getInstance()

    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/program`, {
        'uuid': this.props.match.params.id,
        'title': this.state.title,
        'section': this.state.section,
        'intro': editorInstance.getMarkdown(),
        'website_url': this.state.website_url,
        'tel': this.state.tel,
        'email': this.state.email,
        'gitple_id': this.state.gitple_id,
        'group_uuid': this.state.group_uuid
      }, {withCredentials: true});
      alert('프로그램 내용을 수정했습니다!');
    } catch (e) {
      alert("내용 수정에 실패했습니다.");
    }
  }

  handleDelete = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/program/${this.props.match.params.id}`, {withCredentials: true});
      alert('프로그램을 삭제했습니다!')
      window.location.href = `/program/${this.props.match.params.type}`
    } catch (e) {
      alert("프로그램 삭제에 실패했습니다.");
    }
  }

  getVideo() {
    axios.get(`${process.env.REACT_APP_API_URL}/program/${this.props.match.params.id}/video`, {withCredentials: true}).then(res => {
      this.setState({
        isVideoLoading: false,
        video: res.data
      })
    })
  }

  handleCreateYoutube = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/program/video`, {
        'program_id': this.props.match.params.id,
        'title': this.state.newTitle,
        'youtube_id': this.state.newYoutube,
        'login_required': this.state.newLoginRequired,
      }, {withCredentials: true});
      this.getVideo();
      this.close();
    } catch (e) {
      alert("홍보 영상 추가에 실패했습니다.");
    }
  }

  handleDeleteYoutube = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/program/video/${id}`, {withCredentials: true});
      this.getVideo();
    } catch (e) {
      alert("영상 삭제에 실패했습니다.");
    }
  }

  toggle = () => this.setState((prevState) => ({ newLoginRequired: !prevState.newLoginRequired }))

  render() {
    return (
      <div>
        <h1>프로그램 소개 수정</h1>
        <p className='mb-8'>소개를 작성 및 수정하거나, 홈페이지, 연락처, 홍보 영상 등을 추가할 수 있습니다.</p>
        {
          (!this.state.isLoading) ?
            <Form>
              <Form.Input
                required
                name='title'
                fluid
                label='프로그램 이름'
                placeholder='프로그램 이름을 입력해주세요.'
                onChange={this.handleChange} value={this.state.title}
              />
              <Form.Input
                required
                name='section'
                fluid
                label='섹션'
                placeholder='프로그램 섹션 제목을 입력해주세요.'
                onChange={this.handleChange} value={this.state.section}
              />
              <div className='ui field'>
                <label>소개</label>
              </div>
              <Editor
                previewStyle="vertical"
                height='500px'
                ref={this.editorRef}
                initialValue={this.state.intro ?? ''}
                setValue={this.state.intro}
                hooks={{
                  addImageBlobHook: async (blob, callback) => {
                    const {file, url, status, msg} = await S3UploadFile(blob, 'program/description');
                    if (status === 0) {
                      alert(msg);
                      return false;
                    }
                    callback(encodeURI(url), file);
                    return false;
                  }
                }}
              />
              <Form.Input className='mt-5' label='홈페이지' name='website_url' placeholder="홈페이지 주소를 입력해주세요."
                          onChange={this.handleChange} value={this.state.website_url}/>
              <Form.Group widths='equal'>
                <Form.Input
                  required
                  name='tel'
                  fluid
                  label='담당자 연락처'
                  placeholder='전화번호를 입력해주세요.'
                  onChange={this.handleChange} value={this.state.tel}
                />
                <Form.Input
                  required
                  name='email'
                  fluid
                  label='담당자 이메일'
                  placeholder='이메일 주소를 입력해주세요.'
                  onChange={this.handleChange} value={this.state.email}
                />
              </Form.Group>
              {/*<Form.Input className='mt-5' label='깃플챗 ID' name='gitple_id' placeholder="깃플챗 계정이 있다면 ID를 입력해주세요."*/}
              {/*            onChange={this.handleChange} value={this.state.gitple_id}/>*/}
              <Form.Input className='mt-5' label='채팅 그룹 ID' name='group_uuid' placeholder="채팅이 가능한 그룹이 있다면 해당 그룹 ID를 입력해주세요. ( '사용자 관리' > '그룹 관리'에서 확인할 수 있습니다. )"
                          onChange={this.handleChange} value={this.state.group_uuid}/>

              <Form.Group className='mt-7'>
                <Form.Button onClick={this.handleSave}>수정</Form.Button>
                <Form.Button onClick={this.handleDelete}
                             negative>삭제</Form.Button>
              </Form.Group>
            </Form>
            : <Loader active inline='centered'/>
        }

        <h3 className='mt-8'>홍보 영상</h3>
        <Button primary className='mb-7' onClick={this.open}>새로운 YouTube 영상 추가</Button>

        {
          !this.state.isVideoLoading ?
            <Grid doubling columns={4}>
              {
                this.state.video.map((v, idx) =>
                  <Grid.Column key={idx}>
                    <ProgramYoutubeCard video={v} delete={this.handleDeleteYoutube.bind(this, v.uuid)}/>
                  </Grid.Column>
                )
              }
            </Grid>
            : <Loader active inline='centered'/>
        }


        {/* 새로운 YouTube 추가 */}
        <Modal
          onClose={() => this.close()}
          onOpen={() => this.open()}
          open={this.state.open}
        >
          <Modal.Header>새로운 홍보 영상 추가하기</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form>
                <Form.Input
                  required label='홍보 영상 타이틀' name='newTitle'
                  placeholder="제목을 입력해주세요."
                  onChange={this.handleChange}
                />
                <Form.Input
                  required label='YouTube 영상 ID' name='newYoutube'
                  placeholder="ID를 입력해주세요. (ex. youtube.com/watch?v=[여기가 ID 부분입니다])"
                  onChange={this.handleChange}
                />
                <div className='ui field is-flex is-vcentered'>
                  <label className='mr-5'>로그인 필요 여부</label>
                  <Form.Checkbox
                    value={this.state.newLoginRequired}
                    onChange={this.toggle}
                    checked={this.state.newLoginRequired}
                  />
                </div>
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.close()}>
              취소
            </Button>
            <Button
              content="업로드"
              labelPosition='right'
              icon='checkmark'
              onClick={this.handleCreateYoutube}
              color='blue'
            />
          </Modal.Actions>
        </Modal>
      </div>
    )
  }
}

export default ProgramPage;
