import axios from "axios";
import moment from "moment";
import React, { Component, createRef } from "react";
import { Editor } from "@toast-ui/react-editor";
import {
  Button,
  Icon,
  Divider,
  Modal,
  Image,
  Header,
  Form,
} from "semantic-ui-react";
import ActivityNoticeModal from "./activity.notice.modal";

export default class ActivityNotice extends Component {
  editorRef = React.createRef();
  constructor(props) {
    super(props);

    this.child = createRef();
    this.state = {
      isLoaded: false,
      activityId: this.props.id,
      open: false,
    };
  }

  getData = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/activity/notice/activities/${this.state.activityId}`
    );
    console.log(res);

    this.setState({
      notices: res.data.notices,
      isLoaded: true,
    });
  };

  async componentDidMount() {
    this.getData();
  }

  handleSave = async () => {
    const editorInstance = this.editorRef.current.getInstance();
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/activity/notice/${this.state.activityId}`,
        {
          title: this.state.activityTitle,
          desc: editorInstance.getMarkdown(),
        },
        { withCredentials: true }
      );
      alert("액티비티 공지를 생성했습니다!");
      this.getData();
    } catch (e) {
      alert("액티비티 공지 생성에 실패했습니다.");
    }
    this.setState({ open: false });
  };

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value,
    });
  };

  openActivityModal(postId) {
    this.child.current.handleGetData(postId);
  }

  render() {
    if (!this.state.isLoaded) return null;

    const addNoticeModal = (
      <Modal
        onClose={() => this.setState({ open: false })}
        onOpen={() => this.setState({ open: true })}
        open={this.state.open}
      >
        <Modal.Header>새로운 공지 생성</Modal.Header>
        <Modal.Content image>
          <Form>
            <Form.Input className='full-w'
              required
              label="공지 제목"
              name="activityTitle"
              placeholder="제목을 입력해주세요."
              onChange={this.handleChange}
            />

            <p className="fw-b">공지 설명</p>
            <Editor
              previewStyle="vertical"
              ref={this.editorRef}
              hooks={{
                addImageBlobHook: async (blob, callback) => {
                  const response = await this.uploadEditorImage(blob);
                  const { file, url } = response.data;
                  callback(url, file); // callback(src, alt);
                  return false;
                },
              }}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button positive onClick={this.handleSave}>
            <Icon name="checkmark" />
            공지 생성
          </Button>
        </Modal.Actions>
      </Modal>
    );

    return (
      <>
        {addNoticeModal}
        <ActivityNoticeModal ref={this.child} id={this.state.activityId} setParentData={this.getData}/>
        <div>
          <span
            onClick={() => this.setState({ open: true })}
            className="mr-5 mb-2 ph-5 pv-2 hover-main p-round-big bg-lmain color-white fw-b pointer"
          >
            <Icon name="plus" />
            공지 추가
          </span>

          {this.state.notices.length < 1 ? (
            <p>There is no notices</p>
          ) : (
            <div className="mt-5">
              {this.state.notices.map((post, idx) => (
                <div className="border-a ">
                  <div
                    className="hover-gray pv-5 ph-6"
                    style={{ height: "67px" }}
                    onClick={() => this.openActivityModal(post.uuid)}
                  >
                    {post.title}
                    <div className="color-gray fs-m1">
                      {moment(post.created_at).format("YYYY년 MM월 DD일 HH:mm")}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </>
    );
  }
}
