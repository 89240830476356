import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Line } from 'react-chartjs-2'

const DPV_Line = (props) => {
  const [data, setData] = useState({})
  const {start_date, end_date} = props;
  const color_list = ['#ac92eb', '#4fc1e8', '#a0d568', '#ffce54', '#ed5564']

  useEffect(async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/statistics/DPV?startDate=${start_date}&endDate=${end_date}`);
      const programTypes = Object.keys(res.data)
      setData({
          labels: Object.keys(res.data[programTypes[0]]),
          datasets: programTypes.map((programType, idx) => {
            console.log(idx, 132 - idx * 10)
            return {
              label: programType,
              data:  Object.values(res.data[programType]),
              fill: false,
              backgroundColor: `${color_list[idx]}`,
              borderColor: `${color_list[idx]}80`,
            }
          })
        })
    } catch (err) {
      alert("통계 데이터를 불러오는데 실패했습니다.")
    }
  }, [start_date, end_date])

  return (
    <div>
      <h2 className='mt-7'>일일 프로그램 조회</h2>
      <Line data={data}/>
    </div>
  )

}

export default DPV_Line