import {Component} from "react";
import {Icon} from "semantic-ui-react";
import {Link} from "react-router-dom";

export default class ProgramCard extends Component {
  render() {
    return (
      <Link to={{
        pathname: `/program/${this.props.type}/${this.props.program.uuid}`,
        state: {name: this.props.program.title}
      }}>
        <div className='p-card pv-5 ph-6 hover-zoom pointer is-flex is-vcentered' >
          <div style={{flexGrow: 1}}>
            <p className='color-gray mb-2'>{this.props.program.section}</p>
            <p className='color-black'>{this.props.program.title}</p>
          </div>
          <span className='color-main'><Icon name='arrow circle right'/></span>
        </div>
      </Link>
    );
  }
}