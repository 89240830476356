import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Grid} from "semantic-ui-react";
import IntroCard from "../widgets/intro.card";

class IntroPage extends Component {
  render() {
    return (
      <div>
        <h1>SSP 소개</h1>
        <p>다양한 SSP 프로그램들의 소개를 수정합니다.</p>

        <Grid className='mt-7' doubling columns={4}>
          <Grid.Column>
            <Link to="/program/incubator">
              <IntroCard imgPath={'incubator'} title={'창업보육'}/>
            </Link>
          </Grid.Column>
          <Grid.Column>
            <Link to="/program/market">
              <IntroCard imgPath={'market'} title={'판로지원'}/>
            </Link>
          </Grid.Column>
          <Grid.Column>
            <Link to="/program/investment">
              <IntroCard imgPath={'investment'} title={'투자연계'}/>
            </Link>
          </Grid.Column>
          <Grid.Column>
            <Link to="/program/network">
              <IntroCard imgPath={'network'} title={'네트워크'}/>
            </Link>
          </Grid.Column>
        </Grid>
      </div>
    )
  }
}

export default IntroPage;
