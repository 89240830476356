import React, {Component} from "react";
import {Button, Form, Grid, Loader, Modal} from "semantic-ui-react";
import {Editor} from "@toast-ui/react-editor";
import axios from "axios";
import {S3UploadFile} from "../../../../utills/s3-file-upload";
import ProgramYoutubeCard from "../widgets/program.youtube.card";

class OfficeSection extends Component {

  editorRef = React.createRef()

  constructor(props) {
    super(props)
    this.state = {
      open: false,
      isLoading: true,
      isVideoLoading: true,
      video: []
    }
  }

  async componentDidMount() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/program/${this.props.match.params.id}`)
      this.getVideo();
      const existOffice = response.data;

      this.setState({
        isLoading: false,
        title: existOffice.title,
        section: existOffice.section,
        img_url: existOffice.img_url,
        intro: existOffice.intro,
      });
    } catch (e) {
      console.log(e);
    }
  }

  open = () => this.setState({open: true})
  close = () => this.setState({open: false})

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  handleSave = async () => {
    const editorInstance = this.editorRef.current.getInstance()

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/program`, {
        'uuid': this.props.match.params.id,
        'title': this.state.title,
        'section': this.state.section,
        'img_url': this.state.img_url,
        'intro': editorInstance.getMarkdown(),
      }, {withCredentials: true});
      alert('내용을 수정했습니다!');
    } catch (e) {
      alert("내용 수정에 실패했습니다.");
    }
  }

  handleDelete = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/program/${this.props.match.params.id}`, {withCredentials: true});
      alert('오피스를 삭제했습니다!')
      window.location.href = `/program/incubator`
    } catch (e) {
      alert("오피스 삭제에 실패했습니다.");
    }
  }

  handleImageUpload = async (e) => {
    const {status, url, msg} = await S3UploadFile(e.target.files[0], "office/image");
    if (status === 1) {
      this.setState({
        [e.target.name]: url,
      })
    } else {
      alert(msg);
    }
  }

  getVideo() {
    axios.get(`${process.env.REACT_APP_API_URL}/program/${this.props.match.params.id}/video`).then(res => {
      this.setState({
        isVideoLoading: false,
        video: res.data
      })
    })
  }

  handleCreateYoutube = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/program/video`, {
        'program_id': this.props.match.params.id,
        'title': this.state.newTitle,
        'youtube_id': this.state.newYoutube
      }, {withCredentials: true});
      this.getVideo();
      this.close();
    } catch (e) {
      alert("홍보 영상 추가에 실패했습니다.");
    }
  }

  handleDeleteYoutube = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/program/video/${id}`, {withCredentials: true});
      this.getVideo();
    } catch (e) {
      alert("영상 삭제에 실패했습니다.");
    }
  }

  render() {
    return (
      <div>
        <h1>오피스 소개 수정</h1>
        <p className='mb-8'>소개를 작성 및 수정하거나, 미리보기 이미지를 추가할 수 있습니다.</p>
        {
          (!this.state.isLoading) ?
            <Form>
              <div className='ui field'>
                <label>미리보기</label>
              </div>
              <div style={{maxWidth: '400px', borderRadius: '10px', overflow: 'hidden'}}>
                <div className='p-box-16by9'>
                  <div className='p-box-ch'>
                    <img alt='office img' style={{width: '100%', height: '100%', objectFit: 'cover'}}
                         src={this.state.img_url ?? 'https://react.semantic-ui.com/images/wireframe/square-image.png'}/>
                  </div>
                </div>
              </div>
              <label htmlFor="upload">
                <span className='ui button basic mv-5'>미리보기 사진 업로드</span>
                <input name='img_url' type="file" id="upload" style={{display: 'none'}}
                       onChange={this.handleImageUpload}/>
              </label>

              <Form.Input
                required
                name='title'
                fluid
                label='오피스 이름'
                placeholder='오피스 이름을 입력해주세요.'
                onChange={this.handleChange} value={this.state.title}
              />
              <Form.Input
                required
                name='section'
                fluid
                label='주소'
                placeholder='오피스 주소를 입력해주세요.'
                onChange={this.handleChange} value={this.state.section}
              />
              <div className='ui field'>
                <label>소개</label>
              </div>
              <Editor
                previewStyle="vertical"
                height='700px'
                ref={this.editorRef}
                initialValue={this.state.intro ?? ''}
                setValue={this.state.intro}
                hooks={{
                  addImageBlobHook: async (blob, callback) => {
                    const {file, url, status, msg} = await S3UploadFile(blob, 'office/description');
                    if (status === 0) {
                      alert(msg);
                      return false;
                    }
                    callback(encodeURI(url), file);
                    return false;
                  }
                }}
              />
              <Form.Group className='mt-7'>
                <Form.Button onClick={this.handleSave}>수정</Form.Button>
                <Form.Button onClick={this.handleDelete}
                             negative>삭제</Form.Button>
              </Form.Group>
            </Form>
            : <Loader active inline='centered'/>
        }
        <h3 className='mt-8'>홍보 영상</h3>
        <Button primary className='mb-7' onClick={this.open}>새로운 YouTube 영상 추가</Button>

        {
          !this.state.isVideoLoading ?
            <Grid doubling columns={4}>
              {
                this.state.video.map((v, idx) =>
                  <Grid.Column key={idx}>
                    <ProgramYoutubeCard video={v} delete={this.handleDeleteYoutube.bind(this, v.uuid)}/>
                  </Grid.Column>
                )
              }
            </Grid>
            : <Loader active inline='centered'/>
        }


        {/* 새로운 YouTube 추가 */}
        <Modal
          onClose={() => this.close()}
          onOpen={() => this.open()}
          open={this.state.open}
        >
          <Modal.Header>새로운 홍보 영상 추가하기</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form>
                <Form.Input
                  required label='홍보 영상 타이틀' name='newTitle'
                  placeholder="제목을 입력해주세요."
                  onChange={this.handleChange}
                />
                <Form.Input
                  required label='YouTube 영상 ID' name='newYoutube'
                  placeholder="ID를 입력해주세요. (ex. youtube.com/watch?v=[여기가 ID 부분입니다])"
                  onChange={this.handleChange}
                />
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.close()}>
              취소
            </Button>
            <Button
              content="업로드"
              labelPosition='right'
              icon='checkmark'
              onClick={this.handleCreateYoutube}
              color='blue'
            />
          </Modal.Actions>
        </Modal>
      </div>
    )
  }
}

export default OfficeSection;
