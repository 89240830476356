import React, {Component} from 'react';
import {Button, Table} from 'semantic-ui-react'
import axios from 'axios';
import moment from "moment";
import {Link} from 'react-router-dom'

class Group extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: [],
      isLoaded: false
    }
  }

  async componentDidMount() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/group`);
      this.setState({
        groups: response.data,
        isLoaded: true
      })
    } catch (e) {
      alert("그룹 정보 로딩에 실패했습니다.");
    }
  }


  render() {
    return (
      <div>
        <p>SSP에 등록된 벤처, 파트너 등의 목록입니다. 그룹 이름을 클릭해 그룹 프로필과 그룹에 등록된 유저를 확인할 수 있습니다.</p>
        <div className='mv-7'>
          <Link to={'/people/group/create'}>
            <Button primary>그룹 생성</Button>
          </Link>
        </div>
        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1}>#</Table.HeaderCell>
              <Table.HeaderCell width={4}>그룹 이름</Table.HeaderCell>
              <Table.HeaderCell width={2}>타입</Table.HeaderCell>
              <Table.HeaderCell width={5}>서브타입</Table.HeaderCell>
              <Table.HeaderCell width={3}>등록일</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.state.isLoaded ?
              this.state.groups.map((group, idx) =>
                <Table.Row key={idx}>
                  <Table.Cell>{idx + 1}</Table.Cell>
                  <Table.Cell>
                    <Link to={`/people/group/${group.uuid}`} className='color-main'>
                      {group.name}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>
                    {group.type}
                  </Table.Cell>
                  <Table.Cell>
                    {group.subtype.map((subtype) => {
                      return <span className="ui label mv-1">{subtype.name}</span>
                    })}
                  </Table.Cell>
                  <Table.Cell>
                    {moment(group.created_at).format('YYYY년 MM월 DD일 HH:mm')}
                  </Table.Cell>
                </Table.Row>
              )
              : null
            }
          </Table.Body>

        </Table>

        <div className='mv-7 is-flex' style={{justifyContent: 'flex-end'}}>
          <Link to={'/people/group/auto_create'}>
            <Button className={''}>엑셀 파일로 그룹 자동 생성</Button>
          </Link>
        </div>

      </div>
    )
  }
}

export default Group;
