import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Line } from 'react-chartjs-2'

const DRU_Line = (props) => {
  const [DRU_data, setDRU_data] = useState([])
  const {start_date, end_date} = props;

  useEffect(async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/statistics/DRU?startDate=${start_date}&endDate=${end_date}`);
      setDRU_data(res.data);
    } catch (err) {
      alert("통계 데이터를 불러오는데 실패했습니다.")
    }
  }, [start_date, end_date])

  console.log(DRU_data)

  const data = {
    labels: Object.keys(DRU_data),
    datasets: [
      {
        label: 'daily register user',
        data:  Object.values(DRU_data),
        fill: false,
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: 'rgba(255, 99, 132, 0.2)',
      },
    ],
  }

  return (
    <div>
      <h2 className='mt-7'>일일 가입 유저</h2>
      <Line data={data}/>
    </div>
  )

}

export default DRU_Line