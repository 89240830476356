import axios from "axios";
import moment from "moment";
import { Component } from "react";
import { Button, Icon, Divider, Table } from "semantic-ui-react";

export default class ActivityGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      activityId: this.props.id,
      selectedUsers: [],
      selectList : []
    };
  }

  async componentDidMount() {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/activity/group/${this.state.activityId}`
    );
    console.log(res);

    this.setState({
      groups: res.data.participated_groups,
      selectList: new Array(res.data.participated_groups.length).fill(false),
      isLoaded: true,
    });
  }

  render() {
    if (!this.state.isLoaded) return null;

    const buttonList = [
      {
        name: "모두선택",
        icon: null,
      },
      {
        name: "선택한 그룹 추가",
        icon: "plus",
      },
      {
        name: "선택한 그룹 삭제",
        icon: "minus",
      },
      {
        name: "선택한 그룹에게 이메일 보내기",
        icon: "mail",
      },
    ];

    return (
      <>
        <div>
          {buttonList.map((button, idx) => {
            return (
              <span className="mr-5 mb-2 ph-5 pv-2 hover-main p-round-big bg-lmain color-white fw-b pointer">
                {button.icon ? <Icon name={button.icon} /> : null}
                {button.name}
              </span>
            );
          })}
          <Divider />
          {this.state.groups.length < 1 ? (
            <p>There is no users</p>
          ) : (
            <div>
                <Table celled selectable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={1}>#</Table.HeaderCell>
                      <Table.HeaderCell width={5}>그룹 이름</Table.HeaderCell>
                      <Table.HeaderCell width={2}>이메일</Table.HeaderCell>
                      <Table.HeaderCell width={4}>가입일</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
        
                  <Table.Body>
                    {this.state.groups.map((group,idx) => {
                        <Table.Row key={group.uuid} onClick={() => {this.state.selectList[idx] = !this.state.selectList[idx]; this.setState()}}>
                          <Table.Cell>
                              {this.state.selecteList[idx] ? <Icon name='check'/> : <Icon name='square'/>}
                          </Table.Cell>
                            <Table.Cell>
                              {group.name}
                            </Table.Cell>
                          <Table.Cell>
                              
                          </Table.Cell>
                          <Table.Cell>
                            {moment(group.created_at).format('YYYY년 MM월 DD일 HH:mm')}
                          </Table.Cell>
                        </Table.Row>
                       
                    })
                    }
                  </Table.Body>
                </Table>

              
            </div>
          )}
        </div>
      </>
    );
  }
}
