import React, {Component} from "react";
import '@toast-ui/editor/dist/toastui-editor.css';
import {Editor} from '@toast-ui/react-editor';
import {Button, Divider, Form} from "semantic-ui-react";
import axios from "axios";
import {noticeTypeOption} from './notice.meta';
import NoticeSurvey from './notice.survey'
import NotiTypeSelector from "./notificationType.selector";
import FileUploadButton from "../../../utills/file_upload_button";
import {S3UploadFile, S3UploadFileByFullName} from "../../../utills/s3-file-upload";

class NoticeCreate extends Component {
  constructor(props) {
    super(props);
    this.getSurveyInfo = this.getSurveyInfo.bind(this)
    this.getNotiTarget = this.getNotiTarget.bind(this)
    this.state = {
      surveyTitle: '',
      surveyChecklist:[],
      attachments: [],
      attachments_file: [], // this will be used for uploading file to s3
      attachments_edit: [], // we will traverse this when submit
      attachments_name: [], // will be revealed in the list
      notiTypes: [],
      notiSubtypes: [],
      notiKeywords: []
    }
  }

  getSurveyInfo(title, checklist){
    this.setState({
      surveyTitle: title,
      surveyChecklist: checklist,
    })
  }
  getNotiTarget(notiType, notiSubtype, notiKeyword){
    this.setState({
      notiTypes: notiType,
      notiSubtypes: notiSubtype,
      notiKeywords: notiKeyword,
    })
  }

  editorRef = React.createRef();

  uploadEditorImage = (blob) => {
    const formData = new FormData();
    formData.append('file', blob);

    return axios.post(`${process.env.REACT_APP_API_URL}/files`,
      formData, {withCredentials: true});
  }


  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  handleSave = async () => {
    const editorInstance = this.editorRef.current.getInstance()
    try {
      // processing attachment files
      for (let idx = 0; idx < this.state.attachments_edit.length; idx++) {
        if (this.state.attachments_file[idx]) {
          const { url } = await S3UploadFileByFullName(this.state.attachments_file[idx], `notice/attachment`);
          this.state.attachments.push(url); // should save encodedURI due to comma problem of 'simple-array'
        }
        this.setState({
          attachments: this.state.attachments
        });
      }

      await axios.post(`${process.env.REACT_APP_API_URL}/board/notice`, {
        'title': this.state.title,
        'type': this.state.type,
        'content': editorInstance.getMarkdown(),
        'attachments': this.state.attachments,
        'survey_title' : this.state.surveyTitle,
        'options' : this.state.surveyChecklist,
        'notice_types' : this.state.notiTypes,
        'notice_subtypes' : this.state.notiSubtypes,
        'notice_keywords' : this.state.notiKeywords,

      }, {withCredentials: true});
      alert('게시글을 생성했습니다!');
      window.location.href = "/board/notice"
    } catch (e) {
      alert("게시글 저장에 실패했습니다.");
    }
  }

  handleMultiFileUpload = async (event) => {
    if (event.target.files.length) {
      let file = event.target.files[0];
      this.state.attachments_file.push(file); // this will be used for uploading file to s3
      this.state.attachments_edit.push(file.name); // we will traverse this when submit
      this.state.attachments_name.push(file.name); // will be revealed in the list
      this.setState({
        attachments_file: this.state.attachments_file,
        attachments_edit: this.state.attachments_edit,
        attachments_name: this.state.attachments_name
      });
    }
  }

  render() {
    return (
      <div>
        <h3>공지사항 생성</h3>
        <Form>
          <Form.Input required label='공지글 제목' name='title' placeholder="제목을 입력해주세요." onChange={this.handleChange}/>
          <Form.Select required label='공지글 타입' options={noticeTypeOption} name='type' placeholder="게시글 타입을 선택해주세요." onChange={this.handleChange}/>
          <div className='ui field required'>
            <label>글 내용</label>
            <Editor
              height='500px'
              previewStyle="vertical"
              ref={this.editorRef}
              placeholder='내용을 입력해주세요. 마크다운 문법을 지원하여 이미지나 표 등을 자유롭게 넣을 수 있습니다.'
              hooks={{
                addImageBlobHook: async (blob, callback) => {
                  const {file, url, status, msg} = await S3UploadFile(blob, 'notice/content');
                  if (status === 0) {
                    alert(msg);
                    return false;
                  }
                  callback(encodeURI(url), file);
                  return false;
                }
              }}
            />
          </div>


          <p className='fw-b mt-7'>파일 첨부</p>
          <p>최대 5개의 파일을 첨부할 수 있습니다.</p>
          {
            this.state.attachments_edit.length ?
              <div>
                {
                  this.state.attachments_edit.map((attachment, idx) => {
                    if (!attachment) return null;
                    return (
                      <div key={idx} className='p-card pd-1 mv-5 p-item-vcentered'>
                        <a className='color-main' href={attachment}
                           style={{flexGrow: 1}}>{window.decodeURIComponent(this.state.attachments_name[idx])}</a>
                        <Button basic size="small" icon="close"
                                onClick={() => {
                                  this.state.attachments_file[idx] = null;
                                  this.state.attachments_edit[idx] = null;
                                  this.state.attachments_name[idx] = null;
                                  this.setState({
                                    attachments_file: this.state.attachments_file,
                                    attachments_edit: this.state.attachments_edit,
                                    attachments_name: this.state.attachments_name
                                  })
                                }}
                        />
                      </div>
                    );
                  })
                }
                {
                  (this.state.attachments_edit.filter(
                    (elt) => elt != null
                  ).length <= 4) ?
                    <FileUploadButton
                      label="파일 추가"
                      accept="file/*"
                      name="attachments"
                      handleChange={this.handleMultiFileUpload}
                    /> :
                    null
                }
              </div> :
              <div>
                <p className='color-gray'>등록된 첨부파일이 없습니다.</p>
                <FileUploadButton
                  label="파일 추가"
                  accept="file/*"
                  name="attachments"
                  handleChange={this.handleMultiFileUpload}
                />
              </div>
          }

          <NoticeSurvey setSurveyInfo={this.getSurveyInfo} title={''} checklist={[]}/>
          
          <NotiTypeSelector setNotiTarget={this.getNotiTarget}/>

          <Form.Button className='mv-7' primary onClick={this.handleSave}>저장</Form.Button>
        </Form>
      </div>
    )
  }
}

export default NoticeCreate;