import {Component} from "react";

export default class StepperTitle extends Component {
  render() {
    return (
      <div style={{display: 'flex', alignItems: 'center'}}>
        <div className='no-drag fs-1 has-item-centered' style={{lineHeight: '0', backgroundColor: '#005386', padding: '10px', color: '#fff', width: '36px', height: '36px', borderRadius: '18px'}}>
          {this.props.step}
        </div>
        <p className='fs-1 pl-5'>{this.props.title}</p>
      </div>
    );
  }
}

export function StepperContent(props) {
  return <div style={{borderLeft: '2px #f1f1f1 solid', padding: '20px', marginLeft: '17px'}}>
    {props.children}
  </div>;
}