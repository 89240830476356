import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import {Button, Container, Divider, Icon, Menu, Sidebar} from "semantic-ui-react";
import axios from "axios";
import Navbar from "./components/navbar/navbar";
import Home from "./pages/home/home";
import GroupModule from "./pages/people/group/group.module";
import BoardModule from "./pages/board/board.module";
import Footer from "./components/footer/footer";
import PeopleModule from "./pages/people/people.module";
import "./App.css";
import "./utills/utill.css";
import UserContext from "./UserContext";
import InvestModule from "./pages/program/investment/invest.module";
import ProgramModule from "./pages/program/program.module";
import ActivityModule from "./pages/program/activity/activity.module";
import Statistics from './pages/statistics/statistics'

class App extends Component {
  state = { visible: false };

  async componentDidMount() {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/verifyToken`,
        { withCredentials: true }
      );
      const user = res.data;
      if (user.group.type !== "ADMIN") {
        alert("관리자 그룹이 아닙니다!");
        window.location.href = 'https://poscossp.com';
      }
      this.setState({
        isPassed: true,
        user: user,
      });
    } catch (err) {
      // window.location.href = 'https://api.poscossp.com/v1/auth/login?redirect=' + window.location.href;
    }
  }

  login = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/login?redirect=${window.location.href}`;
  }

  open = () => {
    this.setState({ visible: true });
  };

  goto = (loc) => {
    this.props.history.push(loc);
    this.setState({ visible: false });
  };

  render() {
    return (
      <UserContext.Provider value={this.state.user}>
        {
          this.state.user && this.state.user.group.type === 'ADMIN' ?
            <div style={{ height: "inherit" }}>
              <Navbar openSidebar={this.open} />
              <Sidebar
                animation="push"
                onHide={() => this.setState({ visible: false })}
                vertical
                visible={this.state.visible}
                style={{
                  justifyContent: "center",
                  display: "flex",
                  height: "100%",
                  background: "#fff",
                  zIndex: 5,
                }}
              >
                <Menu
                  secondary
                  vertical
                  className="pv-5"
                  style={{ marginTop: "65px" }}
                >
                  <Menu.Item
                    name="사용자 관리"
                    onClick={this.goto.bind(this, "/people")}
                  />
                  <Divider />
                  <Menu.Header className="mb-5">프로그램</Menu.Header>
                  <Menu.Item
                    name="SSP 소개"
                    onClick={this.goto.bind(this, "/program/intro")}
                  />
                  <Menu.Item
                    name="Biz Day"
                    onClick={this.goto.bind(this, "/program/bizday")}
                  />
                  <Menu.Item
                    name='투자 지원'
                    onClick={this.goto.bind(this, '/investment')}/>
                  <Menu.Item
                    name="SSP 액티비티"
                    onClick={this.goto.bind(this, "/activity/home")}
                  />
                  <Divider />
                  <Menu.Header className="mb-5">게시판</Menu.Header>
                  <Menu.Item
                    name="공지"
                    onClick={this.goto.bind(this, "/board/notice")}
                  />
                  <Menu.Item
                    name="설문지"
                    onClick={this.goto.bind(this, "/board/survey")}
                  />
                  <Menu.Item
                    name="기업시민 활동"
                    onClick={this.goto.bind(this, "/board/citizen")}
                  />
                  <Divider />
                  <Menu.Item name="통계" onClick={this.goto.bind(this, "/")} />
                  <div className="pb-5" />
                </Menu>
              </Sidebar>
              <Sidebar.Pushable>
                <Sidebar.Pusher dimmed={this.state.visible}>
                  <div style={{ marginTop: "85px" }}>
                    <Container>
                      <Route>
                        <Switch>
                          {/*<Route exact path={"/login"} component={}/>*/}
                          <Route path={"/people"} component={PeopleModule} />
                          <Route path={"/group"} component={GroupModule} />
                          <Route path={"/board"} component={BoardModule} />
                          <Route path={"/program"} component={ProgramModule} />
                          <Route path={"/investment"} component={InvestModule}/>
                          <Route path={"/activity"} component={ActivityModule} />
                          <Route path={"/statistics"} component={Statistics} />
                          <Route component={Home} />
                        </Switch>
                      </Route>
                      <Footer />
                    </Container>
                  </div>
                </Sidebar.Pusher>
              </Sidebar.Pushable>
            </div>
            : <div className='pd-1'>
              <p>로그인이 필요합니다!</p>
              <Button
                style={{boarder: "none"}}
                onClick={this.login}
              >
                <Icon name='sign in'/> 로그인
              </Button>
            </div>
        }
      </UserContext.Provider>
    );
  }
}

export default withRouter(App);
