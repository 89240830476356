import {Component} from "react";

export default class DetailMsgAdmin extends Component {
  render() {
    return (
      <div className='p-card pd-1 mb-5'>
        <p style={{color: '#005386'}}><b>[관리자]</b></p>
        <p>
          {
            this.props.content.split('\n').map((str) => {
              return (<div>{str}</div>)
            })
          }
        </p>
        <p style={{color: 'gray', fontSize: '0.9em'}}>{this.props.date}</p>
      </div>
    );
  }
}