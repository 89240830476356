import React, {Component} from "react";
import {
  Button,
  Container, Divider, Icon, Label,
} from "semantic-ui-react";
import {Link} from "react-router-dom";
import axios from "axios";


class BizdayListPage extends Component {
  state = {isLoaded: false, bizDays: []};

  async componentDidMount() {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/bizday/group?slug=${this.props.match.params.slug}`, {withCredentials: true});
    this.setState({
      bizGroup: res.data,
      isLoaded: true,
    });

    const bizDays = await axios.get(`${process.env.REACT_APP_API_URL}/bizday/biz-group/${res.data.uuid}`, {withCredentials: true});
    this.setState({
      bizDays: bizDays.data
    });
  }

  render() {
    return (
      <div>
        {
          this.state.isLoaded ?
            <>
              <h1>{this.state.bizGroup.name} Biz Day</h1>
              <p className='color-gray'>
                {
                  this.state.bizGroup.description.split('\n').map((str) => {
                    return (<div>{str}</div>)
                  })
                }
              </p>

              <div className='mv-7'>
                <Link to={`/program/bizday/group-setting/${this.state.bizGroup.uuid}`}>
                  <Button primary className='mb-5 mr-5'><Icon name='cogs'/> 그룹 설정</Button>
                </Link>
                <Link to={`/program/bizday/${this.props.match.params.slug}/${this.state.bizGroup.uuid}/new-bizday`}>
                  <Button primary className='mb-5'><Icon name='add'/> 새로운 비즈데이 시작</Button>
                </Link>
              </div>

              {
                this.state.bizDays.map((bizDay, idx) =>
                  <Link to={`/program/bizday/${this.props.match.params.slug}/${bizDay.uuid}`} key={idx}>
                    <div className='p-card pv-5 ph-7 mv-6 hover-zoom pointer is-flex is-vcentered'>
                      <div style={{flexGrow: 1}}>
                        <p className='fs-1 fw-b color-black'>{bizDay.title}</p>
                        <div>
                          {
                            bizDay.category_1.map((cat) => <Label className='mb-1'>{cat}</Label>)
                          }
                        </div>
                        <div>
                          {
                            bizDay.category_2.map((cat) => <Label className='mb-1'>{cat}</Label>)
                          }
                        </div>
                      </div>
                      <div style={{flexShrink: 0, textAlign: 'center'}}>
                        <Icon className={`fs-3 ${bizDay.status ? 'color-main' : 'color-gray'}`} name={bizDay.status ? 'toggle on' : 'toggle off'}/>
                        <p className={`fw-b ${bizDay.status ? 'color-main' : 'color-gray'}`}>{bizDay.status ? '모집 중' : '모집 마감'}</p>
                      </div>
                    </div>
                  </Link>
                )
              }
            </>
            : null
        }
      </div>
    );
  }
}

export default BizdayListPage;