import React, {Component} from "react";
import '@toast-ui/editor/dist/toastui-editor.css';
import {Editor} from '@toast-ui/react-editor';
import {Form} from "semantic-ui-react";
import axios from "axios";
import {S3UploadFile} from "../../../utills/s3-file-upload";

class CitizenCreate extends Component {
  editorRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      citizenTypeOption: []
    };
    this.getTypeList()
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  handleSave = async () => {
    const editorInstance = this.editorRef.current.getInstance()
    try {
      if (this.state.preview_img_url_file) {
        const {url} = await S3UploadFile(this.state.preview_img_url_file, "citizen/preview");
        this.setState({
          'preview_img_url': url
        })
      }

      await axios.post(`${process.env.REACT_APP_API_URL}/board/corpoCitizen`, {
        title: this.state.title,
        type: this.state.type,
        content: editorInstance.getMarkdown(),
        tags: this.state.tags,
        preview_img_url: this.state.preview_img_url
      }, {withCredentials: true});
      alert('게시글을 생성했습니다!');
      window.location.href = "/board/citizen"
    } catch (e) {
      alert("게시글 저장에 실패했습니다. " + e.response.data.message);
    }
  }

  async getTypeList() {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/board/corpoCitizen/type/all`, {withCredentials: true});
      const citizenTypeOption = []
      for (const type of res.data) {
        citizenTypeOption.push({
          key: type.uuid,
          text: type.title,
          value: type.uuid
        })
      }

      this.setState({
        citizenTypeOption: citizenTypeOption
      })
    } catch (e) {
      alert("문제가 발생했습니다. 잠시 뒤 다시 시도하거나 개발자에게 문의하세요.");
    }
  }

  handleFileUpload = (event) => {
    if (event.target.files.length) {
      const fileReader = new FileReader();
      let file = event.target.files[0];
      fileReader.onloadend = () => {
        this.setState({
          [event.target.name + "_file"]: file,
          [event.target.name]: fileReader.result
        });
      }
      fileReader.readAsDataURL(file);
    }
  }

  render() {
    const defaultTags = [];
    const tagsLabel = (tags) => {
      const tag = [];
      for (const t of tags) {
        tag.push({
          key: t,
          text: t,
          value: t
        })
      }
      return tag.concat(defaultTags);
    }

    const renderLabel = (label) => ({
      content: `${label.text}`,
    })

    return (
      <div>
        <h3>게시글 생성</h3>
        <Form>

          <Form.Input required label='제목' name='title' placeholder="제목을 입력해주세요." onChange={this.handleChange}/>
          <Form.Select required label='게시글 타입' options={this.state.citizenTypeOption} name='type'
                       placeholder="게시글 타입을 선택해주세요."
                       onChange={this.handleChange}/>

          <div className='mb-5 ui field'>
            <label>내용</label>
            <Editor
              previewStyle="vertical"
              height='500px'
              ref={this.editorRef}
              hooks={{
                addImageBlobHook: async (blob, callback) => {
                  const {file, url, status, msg} = await S3UploadFile(blob, 'citizen/content');
                  if (status === 0) {
                    alert(msg);
                    return false;
                  }
                  callback(encodeURI(url), file);
                  return false;
                }
              }}
            />
          </div>

          <div className='ui field'>
            <label>미리보기</label>
            <div style={{maxWidth: '400px', borderRadius: '10px', overflow: 'hidden'}}>
              <div className='p-box-16by9'>
                <div className='p-box-ch'>
                  <img alt='preview img' style={{width: '100%', height: '100%', objectFit: 'cover'}}
                       src={this.state.preview_img_url ?? 'https://react.semantic-ui.com/images/wireframe/square-image.png'}/>
                </div>
              </div>
            </div>
            <label htmlFor="upload">
              <span className='ui button basic mv-5'>미리보기 사진 업로드</span>
              <input name='preview_img_url' type="file" id="upload" style={{display: 'none'}}
                     onChange={this.handleFileUpload}/>
            </label>
          </div>


          <Form.Dropdown
            label='태그'
            placeholder='검색 태그를 등록해주세요.'
            fluid
            search
            selection
            multiple
            allowAdditions
            onAddItem={(event, data) => defaultTags.push({key: data.value, text: data.value, value: data.value})}
            options={tagsLabel(this.state.tags)}
            renderLabel={renderLabel}
            value={this.state.tags}
            name='tags'
            onChange={this.handleChange}
          />

          <Form.Group>
            <Form.Button className='mv-7' onClick={this.handleSave}>저장</Form.Button>
          </Form.Group>
        </Form>
      </div>
    )
  }
}

export default CitizenCreate;