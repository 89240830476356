import React, {Component} from "react";
import {Button, Divider, Form, Grid, Icon, Modal} from "semantic-ui-react";
import axios from "axios";
import {Link} from "react-router-dom";
import MatchedNeedsCard from "../widgets/matched.needs.card";

class MatchedNeedsTab extends Component {
  state = {open: false, matchedNeeds: []}

  open = () => this.setState({open: true})
  close = () => this.setState({open: false})

  async componentDidMount() {
    this.getMatchedNeed();
  }

  async getMatchedNeed() {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/bizday/${this.props.bizId}/needs/matched`, {withCredentials: true});
      this.setState({
        matchedNeeds: res.data,
      })
    } catch (e) {
      console.log(e);
    }
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  handleSave = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/bizday/needs/match`, {
        'bizday_id': this.props.bizId,
        'venture_needs_id': this.state.ventureNeedsId,
        'partner_needs_id': this.state.partnerNeedsId
      }, {withCredentials: true});
      this.close();
      // 매칭 정보만 새로
      this.getMatchedNeed();
    } catch (e) {
      alert("새로운 매칭 생성을 실패했습니다. 이미 매칭한 니즈일 수 있습니다.");
    }
  }

  render() {

    const labelOpt = (items) => {
      const opt = [];
      for (const e of items) {
        opt.push({
          key: e.uuid,
          text: e.title,
          value: e.uuid,
          description: `${e.category_1} | ${e.category_2}`,
          image: {avatar: true, src: e.group.profile_url ?? 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'},
        })
      }
      return opt;
    }

    return (
      <div>
        <Button primary className='mb-5' onClick={this.open}>
          <Icon name='add'/> 새로운 매칭하기
        </Button>

        {
          this.state.matchedNeeds.length === 0 ? <p className='color-gray pd-1'>매칭된 사업이 없습니다.</p> : null
        }
        <Grid columns={3} doubling stackable>
          {
            this.state.matchedNeeds.map((m) =>
              <Grid.Column>
                <MatchedNeedsCard m={m}/>
              </Grid.Column>
            )
          }
        </Grid>

        {/* 새로운 매칭하기 */}
        <Modal
          onClose={() => this.close()}
          onOpen={() => this.open()}
          open={this.state.open}
        >
          <Modal.Header>새로운 매칭하기</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form>
                <Form.Dropdown
                  required label='벤처 니즈' name='ventureNeedsId'
                  placeholder="벤처 니즈를 선택해주세요."
                  fluid
                  search
                  selection
                  options={labelOpt(this.props.ventureNeeds)}
                  onChange={this.handleChange}
                />
                <Form.Dropdown
                  required label={`${this.props.groupName} 니즈`} name='partnerNeedsId'
                  placeholder={`${this.props.groupName} 니즈를 선택해주세요.`}
                  fluid
                  search
                  selection
                  options={labelOpt(this.props.partnerNeeds)}
                  onChange={this.handleChange}
                />
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.close()}>
              취소
            </Button>
            <Button
              primary
              content="매칭하기"
              labelPosition='right'
              icon='checkmark'
              onClick={this.handleSave}
            />
          </Modal.Actions>
        </Modal>
      </div>
    )
  }
}

export default MatchedNeedsTab;