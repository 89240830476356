import React, {Component} from "react";
import {Button, Card, CardContent, Form, Grid, Modal} from "semantic-ui-react";
import ProgramCard from "../widgets/program.card";
import PartnerSection from "./partner.section";
import {Link} from "react-router-dom";
import axios from "axios";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};


class IncubatorPage extends Component {
  constructor(props) {
    super(props);
    this.state = {open: false, open2: false, newTitle: '', newSection: '', program: [], office: [], newOffice: '', newAddress: ''};
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  open = () => this.setState({open: true})
  close = () => this.setState({open: false, newTitle: '', newSection: ''})

  open2 = () => this.setState({open2: true})
  close2 = () => this.setState({open2: false, newOffice: '', newAddress: ''})

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  componentDidMount() {
    this.getProgram();
    this.getOffice();
  }

  getProgram() {
    axios.get(`${process.env.REACT_APP_API_URL}/program?type=INCU`).then((res) => {
      this.setState({
        program: res.data
      });
    });
  }

  getOffice() {
    axios.get(`${process.env.REACT_APP_API_URL}/program?type=OFFI`).then((res) => {
      this.setState({
        office: res.data
      });
    });
  }

  updateOrder = async () => {
    try {
      await axios.patch(`${process.env.REACT_APP_API_URL}/program/order`, {
        'list': this.state.program,
      }, {withCredentials: true});
      alert("순서가 성공적으로 변경되었습니다.");
    } catch (e) {
      alert("문제가 발생했습니다. 잠시 뒤 다시 시도하거나 개발자에게 문의하세요.");
    }
  }

  createProgram = async () => {
    if (this.state.newTitle === '' || this.state.newSection === '') {
      alert('섹션 제목과 프로그램 이름을 입력해주세요.')
      return;
    }
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/program`, {
        'title': this.state.newTitle,
        'section': this.state.newSection,
        'type': 'INCU'
      }, {withCredentials: true});
      this.close();
      this.getProgram();
    } catch (e) {
      alert("새로운 프로그램 생성에 실패했습니다.");
    }
  }

  createOffice = async () => {
    if (this.state.newOffice === '' || this.state.newAddress === '') {
      alert('오피스 이름과 주소를 입력해주세요.')
      return;
    }
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/program`, {
        'title': this.state.newOffice,
        'section': this.state.newAddress,
        'type': 'OFFI'
      }, {withCredentials: true});
      this.close2();
      this.getOffice();
    } catch (e) {
      alert("새로운 오피스 생성에 실패했습니다.");
    }
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const program = reorder(
      this.state.program,
      result.source.index,
      result.destination.index
    );

    this.setState({
      program
    });
  }

  render() {
    return (
      <div>
        <h1>창업보육</h1>
        <p>창업보육 카테고리의 프로그램 소개를 작성 및 수정할 수 있습니다.</p>
        <Button color='blue' className='mv-5' onClick={this.open}>새로운 프로그램 추가</Button>

        <h3>프로그램 순서 변경 및 수정</h3>
        <p className='mb-7'>순서 변경 후 아래 저장 버튼을 누르세요. 내용을 수정하려면 원하는 프로그램을 클릭하세요.</p>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {this.state.program.map((item, idx) => (
                  <Draggable key={item.uuid} draggableId={item.uuid} index={idx}>
                    {(provided, snapshot) => (
                      <div
                        className='mb-5'
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <ProgramCard key={idx} type={'incubator'} program={item}/>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <Button className='mv-5' onClick={this.updateOrder}>순서 변경 저장</Button>

        <h3 className='mt-8'>공간 지원</h3>
        <Button color='blue' className='mt-5 mb-8' onClick={this.open2}>새로운 오피스 추가</Button>

        <Grid doubling stackable columns={3}>
          {
            this.state.office.map((o) => <Grid.Column>
              <Link to={{
                pathname: `/program/office/${o.uuid}`,
                state: {name: o.title, pId: o.uuid}
              }}>
                <div className='p-card hover-zoom pointer'>
                  <div className='p-box-16by9'>
                    <div className='p-box-ch'>
                      <img alt='office img' style={{width: '100%', height: '100%', objectFit: 'cover'}}
                           src={o.img_url ?? 'https://react.semantic-ui.com/images/wireframe/square-image.png'}/>
                    </div>
                  </div>
                  <div className='pd-1'>
                    <p className='color-black mb-2'>{o.title}</p>
                    <p className='color-gray'>{o.section}</p>
                  </div>
                </div>
              </Link>
            </Grid.Column>)
          }
        </Grid>

        <h3 className='mt-8'>협업 파트너</h3>
        <p>창업보육 파트너를 관리합니다. 새로운 파트너를 추가하거나 수정해보세요.</p>
        <PartnerSection programType={'INCU'}/>

        <Modal
          onClose={() => this.close()}
          onOpen={() => this.open()}
          open={this.state.open}
        >
          <Modal.Header>새로운 프로그램</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form>
                <Form.Input
                  required label='프로그램 섹션 제목' name='newSection'
                  placeholder="섹션 제목을 입력해주세요. (ex. 창업발굴, 경영지원 등)"
                  onChange={this.handleChange}
                />
                <Form.Input
                  required label='프로그램 이름' name='newTitle'
                  placeholder="프로그램 이름을 입력해주세요. (ex. POSCO Idea Market Place, 예비창업패키지 등)"
                  onChange={this.handleChange}
                />
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.close()}>
              취소
            </Button>
            <Button
              content="생성하기"
              labelPosition='right'
              icon='checkmark'
              onClick={this.createProgram}
              color='blue'
            />
          </Modal.Actions>
        </Modal>

        <Modal
          onClose={() => this.close2()}
          onOpen={() => this.open2()}
          open={this.state.open2}
        >
          <Modal.Header>새로운 오피스</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form>
                <Form.Input
                  required label='오피스 이름' name='newOffice'
                  placeholder="오피스 이름을 입력해주세요. (ex. 포항 체인지업 그라운드 등)"
                  onChange={this.handleChange}
                />
                <Form.Input
                  required label='오피스 주소' name='newAddress'
                  placeholder="오피스 주소를 입력해주세요. (ex. 포항시 남구 청암로 77 POSTECH 등)"
                  onChange={this.handleChange}
                />
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.close2()}>
              취소
            </Button>
            <Button
              content="생성하기"
              labelPosition='right'
              icon='checkmark'
              onClick={this.createOffice}
              color='blue'
            />
          </Modal.Actions>
        </Modal>
      </div>
    )
  }

}

export default IncubatorPage;
