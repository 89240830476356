import {Component} from "react";
import {Icon} from "semantic-ui-react";

export default class IntroCard extends Component {
  render() {
    return (
      <div className='p-card hover-zoom'>
        <div className='pd-1'>
          <p style={{fontWeight: 'bold', fontSize: '1.3em', color: '#000000DE'}}>{this.props.title} <span
            style={{color: '#005386', float: 'right'}}><Icon
            name='arrow circle right'/></span></p>
        </div>
        <div className='p-box-1by1'>
          <div className='p-box-ch is-flex is-vcentered'>
            <div className='pd-1'>
              <img style={{width: '100%', maxHeight: '300px'}} src={`/img/${this.props.imgPath}.svg`}/>
            </div>
          </div>
        </div>
      </div>
    );
  }

}