import React, { Component } from "react";
import { Modal, Button, Icon, Input } from "semantic-ui-react";
import { Editor } from "@toast-ui/react-editor";
import axios from "axios";

export default class ActivityNoticeModal extends Component {
  editorRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isLoaded: false,
      uuid: "",
      title: "",
      desc: "",
    };
  }

  handleGetData = async (postId) => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/activity/notice/${postId}`
    );
    console.log(res);

    this.setState({
      uuid: postId,
      title: res.data.title,
      desc: res.data.desc,
      open: true,
      isLoaded: true,
    });
  };

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value,
    });
  };

  handleDelete = async () => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_URL}/activity/notice/${this.state.uuid}`
      );
      this.setState({
        open: false,
        isLoaded: false,
      });

      this.props.setParentData();
      alert("액티비티 공지를 삭제했습니다!");
    } catch (e) {
      alert("액티비티 공지 삭제에 실패했습니다.");
    }
  };

  handleSave = async () => {
    const editorInstance = this.editorRef.current.getInstance();
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/activity/notice/${this.state.uuid}`,
        {
          title: this.state.title,
          desc: editorInstance.getMarkdown(),
        },
        { withCredentials: true }
      );
      alert("액티비티 공지를 저장했습니다!");
    } catch (e) {
      alert("액티비티 공지 저장에 실패했습니다.");
    }
    this.props.setParentData();
    this.setState({ open: false });
  };

  render() {
    if (!this.state.isLoaded) return null;

    return (
      <Modal
        onClose={() => {
          this.setState({ open: false });
        }}
        onOpen={() => {
          this.setState({ open: true });
        }}
        open={this.state.open}
      >
        <Modal.Header>
          <Input
            fluid
            required
            name="title"
            placeholder="제목을 입력해주세요."
            onChange={this.handleChange}
            value={this.state.title}
          />
        </Modal.Header>
        <Modal.Content scrolling>
          <Editor
            previewStyle="vertical"
            ref={this.editorRef}
            initialValue={this.state.desc}
            hooks={{
              addImageBlobHook: async (blob, callback) => {
                console.log("MYMYMYM addImageBlobHook");
                const response = await this.uploadEditorImage(blob);
                const { file, url } = response.data;
                console.log(url);
                console.log(callback);
                callback(url, file); // callback(src, alt);
                return false;
              },
            }}
          />
        </Modal.Content>

        <Modal.Actions>
          <Button onClick={this.handleDelete}>
            <Icon name="minus" />
            삭제
          </Button>
          <Button positive onClick={this.handleSave}>
            <Icon name="checkmark" />
            저장
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
